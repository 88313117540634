@import url(https://fonts.googleapis.com/css?family=Lato:100,300,400,900);
@import url(https://fonts.googleapis.com/css?family=Lato:100,300,400,900);
/* COLORS
light text - #9da5b4
dark text  - #4b5263

gray bg    - #21252b
light gray bg - #282c34


*/

body {
  background: #21252c !important;
  color: white;
  font-family: lato;
}

#root {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* Overiding the color of the blue button */
.ui.blue.button {
  background-color: #29acff;
}

.ui.avatar.image {
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  height: 35px;
  width: auto;
}

.mediate-icon-small {
  height: 15px;
  width: auto;
}

/* Header */
.nav-container {
  /*background: black;*/
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  justify-content: flex-end;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23) !important;
  z-index: 10;
  /*padding: 5px 10px;*/
}

.nav-container.compact {
  position: absolute;
  right: 0;
  width: auto;
  box-shadow: none !important;
}

.app-page .nav-container {
  position: absolute;
  right: 0;
  width: 70%;
}
.ui.floating.dropdown.labeled.icon.button {
  color: white;
  background: #272b34;
}
.nav-logo {
  height: 35px;
  position: absolute;
  left: 5px;
  top: 5px;
}

.nav-container .ui.top.right.pointing.dropdown.button {
  /*position: absolute;
    right: 25px;
    top: 5px;
    padding: 0;*/
  outline: none;
  z-index: 5001;
}
.nav-container .ui.top.right.pointing.dropdown.button:hover,
.nav-container .ui.button:hover {
  background: none;
}

.nav-container .ui.button {
  background: none;
}
.nav-container .headshot {
  height: 35px;
  width: auto;
  border-radius: 12px;
}
.nav-container .headshot:hover {
  opacity: 0.8;
}

/*** Animations ***/

.mediate-fade-appear {
  opacity: 0;
}

.mediate-fade-appear.mediate-fade-appear-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.mediate-fade-enter {
  opacity: 0;
}

.mediate-fade-enter.mediate-fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.mediate-fade-leave {
  opacity: 0;
}

.mediate-fade-leave.mediate-fade-leave-active {
  opacity: 0;
  transition: opacity 300ms ease-in;
}

/*.nav-container .ui.simple.dropdown.item{
    color: white;
    float: right;
}
.nav-container .ui.inline.dropdown {
    color: white;
    position: absolute;
    right: 5px;
    top: 2px;
    background: #21252b;
    border-radius: 10px;
}
.nav-container .ui.avatar.image {
    border-radius: 0;
}
.nav-container img.ui.avatar.image {
    height: 26px;
    margin-right: 0px;
    border-radius: 10px 0 0 10px;
    border: 1px solid rgba(255, 255, 255, 0.2);
}
.nav-container .ui.inline.dropdown .menu {
    left: -87px;
    border-radius: 0 0 0 5px;
    background: black;
}
.nav-container .ui.dropdown .menu > .item {
    color: white;
}
.nav-container .ui.dropdown .menu > .item:hover{
    background: #21252b;
}*/

/*** Widgets ***/
.mediate-ui-widget-header {
  font-weight: 300;
  color: #000;
  width: 100%;
  background: -o-linear-gradient(top, #ccc 0%, #aaa 100%);
  box-shadow: 0px 2px 0px #575757 inset;
  padding: 5px;
  border-radius: 2px;
}

.mediate-ui-widget {
  background: -o-linear-gradient(top, #303030 0%, #292929 100%);
  border-radius: 2px;
  padding: 10px;
  margin: 10px;
}

.mediate-ui-widget-label {
  font-weight: 300;
  color: #ccc;
}

.mediate-marker-label {
  padding: 5px;
  color: #fff;
}

.admin-marker-label-portal,
.mediate-note-portal {
  position: absolute !important;
  right: 0px !important;
  bottom: 0px !important;
  margin: 35px !important;
  background: #2e3542 !important;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23) !important;
  z-index: 10;
}

.admin-marker-label-portal > * .header,
.admin-marker-label-portal > * .admin-marker-label-description,
.mediate-note-portal > * .header,
.mediate-note-portal-description {
  color: #fff !important;
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.admin-marker-label-extra-content,
.mediate-note-portal-extra-content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
  flex-wrap: wrap;
}

.mediate-current-schema-label {
  align-self: center;
  color: #fff;
  font-size: 24px;
  font-weight: 700;
}

.mediate-sidebar-dimmer.show {
  position: absolute;
  display: block;
  background: rgba(0, 0, 0);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 500ms ease-in-out;
}

.mediate-sidebar-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.mediate-sidebar-dimmer.hide {
  opacity: 0;
}

.mediate-sidebar.hide {
  height: 0;
}
.mediate-sidebar.show {
  height: 80vh;
}
.mediate-sidebar {
  background: #131820;
  position: absolute;
  z-index: 11;
  bottom: 0;
  width: 100vw;
  transition: height 500ms ease-in-out;
  box-shadow: 0 0px 12px rgba(0, 0, 0, 0.36), 0 0px 12px rgba(0, 0, 0, 0.43) !important;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}

.hp-logo {
    position: fixed;
    top: 30px;
    left: 0;
    right: 0;
    margin: auto;
    width: 250px;
}

.hp-login-container{
    width: 350px;
    position: absolute;
    margin: auto;
    left: 0;
    right:0;
    margin-top: 20px;
}

.hp-content {
    text-align: center;
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.hp-body-container,
.hp-body{
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.content__heading {
    margin-bottom: 24px;
    color: #272727;
    font-size: 44px;
}


.hp-body-container {
    background: linear-gradient(-90deg, #feea00, #f94a6f, #9fe966, #28adff);
    background-size: 400% 400%;
    -webkit-animation: gradient 15s ease infinite;
            animation: gradient 15s ease infinite;
    width: 100%;
    height: 100%;
}

.hp-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: rgba(19, 23, 32, 0.5);
}

@-webkit-keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}



.hp-field-margin-0 {
    margin-bottom: 0!important;
}

.hp-forgot-pass-container {
    width: 350px;
    color: white;
    padding: 30px;
}
.hp-forgot-pass-container a{
    background: rgba(0, 0, 0, 0.5);
    padding: 5px 15px;
    margin: 0 5px;
    border-radius: 2px;
    color:#29acff;
}

.hp-login-container form .ui input[name=email],
.hp-login-container form .ui input[name=email]:focus{
    border-radius: 5px 5px 0 0;
}
.hp-login-container form .ui input[name=password],
.hp-login-container form .ui input[name=password]:focus{
    border-radius: 0 0 5px 5px;
}

.mediate-intro-video {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 1;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

/*** NEW SEMANTIC UI LOGIN FORM ***/
.mediate-login-form-field {
  margin-bottom: 0 !important;
}

.mediate-login-submit {
  margin-top: 20px !important;
}

.mediate-login-error.show {
  display: block;
}

.mediate-login-error.hide {
  display: none;
}

.toggle-icon-label {
  cursor: pointer;
}

.mediate-sign-up-header, .hp-tagline {
    padding-bottom: 20px;
    color: #fff;
}

.mediate-sign-up-container, .mediate-verify-account-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0% 10%;
    align-items: center;
    flex-grow: unset;
}

.mediate-sign-up-form {
    width: 400px;
}

.mediate-sign-up-confirmation {
    display: flex;
    flex-direction: column;
}

.mediate-verify-account-message {
    width: 350px;
}

/* MEDIA QUERIES */
@media only screen and (max-width: 700px) {

}

.hp-body-container {
  overflow: hidden;
}
/*Heading*/
.page-header-container {
  padding: 20px 40px;
  width: 100%;
}
.page-header-container h1 {
  font-size: 35px;
  margin: 0px 0px 10px 0px;
  color: #fff;
  width: 100%;
}
.page-header-container h6 {
  font-size: 16px;
  margin: 0;
  color: #9da5b4;
  border-bottom: 1px solid #9da5b4;
  padding-bottom: 5px;
  margin: 5px;
  width: 100%;
}

.group-collaborators h4 {
  color: #9da5b4;
}

.mediate-dashboard-main-content {
  position: relative;
  height: 100%;
  scroll-behavior: smooth;
}

.mediate-dashboard-project-prompt {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  color: #fff;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.mediate-project-prompt-admin-link {
  color: #2185d0;
  margin: 0px 5px;
}

.mediate-research-group-container {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
}

.mediate-dashboard-scroll-container {
  position: relative;
  height: 95%;
}

.mediate-dashboard-main-content {
  flex: 1 1;
  min-width: 0;
}

.mediate-project-menu-toggle {
  color: #fff;
  position: absolute;
  right: 0;
  top: 0;
  margin: 20px !important;
  cursor: pointer;
  z-index: 1;
}

/** Sidebar **/
.mediate-projects-menu {
  background: #090d13;
  position: absolute !important;
  display: flex;
  flex-direction: column;
}

.mediate-projects-menu-list-item {
  margin: 15px;
  cursor: pointer;
}

.mediate-projects-menu-list-container {
  margin-top: 20px !important;
  border-top: 1px solid #282c34;
}

.mediate-filter-projects-container {
  color: #fff;
  display: flex;
  justify-content: stretch;
  align-items: center;
  margin: 20px 0px 10px 10px;
}

.mediate-filter-projects-container > * input {
  color: #fff !important;
}

/** sidebar color - #090d13 **/
.mediate-dashboard-container {
  height: 100%;
  background: #000;
}

/*Videos*/
.dash-video-list-container {
  padding: 10px 40px;
}
.dash-video {
  position: relative;
  width: 49%;
  float: left;
}
.dash-video:nth-child(odd) {
  margin-right: 15px;
}
.dash-video-image {
  width: 100%;
  border-radius: 10px;
}
.dash-video-title {
  position: absolute;
  bottom: 4px;
  left: 0;
  padding: 150px 20px 75px 20px;
  right: 0;
  border-radius: 10px;
  font-size: 27px;
  font-weight: 300;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+99&0+0,0.92+100 */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.91) 99%,
    rgba(0, 0, 0, 0.92) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#eb000000',GradientType=0 ); /* IE6-9 */
}
.dash-video-collab-container {
  position: absolute;
  bottom: 14px;
  left: 20px;
  width: 100%;
  display: block;
}
.dash-video .ui.label {
  position: absolute;
  right: 20px;
  top: 15px;
  background: #282c34;
  color: white;
  padding: 6px;
  font-size: 20px;
  font-weight: 400;
}

.dash-video .captions-label {
  position: absolute;
  left: 20px;
  font-size: 20px;
  background: #282c34;
  padding: 6px;
  border-radius: 2px;
  text-align: center;
  top: 15px;
}
/***
  Hiding this until we can display it a bit better
  TODO find a cleaner way to display active users
***/
.dash-video-collab-container .ui.label.collaborator-icon {
  display: none;
}
.dash-video img.mediate-icon-small {
  height: 15px !important;
  margin-right: 2px;
  margin-bottom: 2px;
}
.dash-video-year {
  position: absolute;
  bottom: 55px;
  left: 20px;
  color: #9da5b4;
}

.mediate-link {
  opacity: 1;
  color: #fff;
}

.mediate-link:hover {
  opacity: 0.7;
  color: #fff;
}

/*** FOR VIDEOS WITH NO THUMBS I.E. AUDIO ONLY ***/

.dash-video.no-thumb .dash-video-title {
  position: relative;
}

.dash-video-icon {
  position: absolute;
  font-size: 150px !important;
  top: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
  margin: auto !important;
  -webkit-filter: blur(3px);
          filter: blur(3px);
}

/* MEDIA QUERIES */
@media only screen and (max-width: 900px) {
  .dash-video {
    width: 100%;
    margin-bottom: 20px;
  }
}

.get-data-container {
  position: relative;
  margin-left: 10px;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100vh;
}

.selected-film-list {
  position: relative;
  background-color: #3e4452;
  border-radius: 5px;
}

.selected-film {
  list-style: none;
}

.selected-film-title {
  display: inline-block;
}

.selected-film .set-query-to-player-button {
  position: relative;
  display: inline-block;
  left: 15px;
  cursor: pointer;
}

.selected-film h1,
.get-data-select-groups h1,
.get-data-page-header {
  font-weight: 300 !important;
  font-size: 35px;
  color: #fff;
}

.selected-film .ui.checkbox {
  padding: 5px;
}

.file-select-buttons .ui.checkbox label {
  color: #fff;
}

.file-select-buttons .ui.radio.checkbox {
  padding: 10px;
}

.file-select-buttons span {
  color: #ccc;
}

.selected-film .ui.checkbox label {
  color: #ccc;
}

.get-data-container h4 {
  font-weight: 300 !important;
  color: #fff;
}

.research-group-marker-type-filter {
  padding: 5px;
}

.get-data-container .ui.multiple.dropdown {
  background: #ccc;
}

/*** Data Download Component ***/
.data-download-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 30px;
}

.data-download-link h4 {
  margin-top: 10px !important;
}

.data-download-link a i.icon {
  font-size: 40px;
}

.data-download-link a {
  color: #ccc;
}

.data-download-link a:hover {
  opacity: 0.6;
}

/*** Read Only Video Player ***/
.read-only-interface-container {
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 100%;
}

.read-only-interface-container .app-marker-list-container ul {
  padding: 0px 0 0 0;
  margin: 0 0 0 70px;
}

.read-only-video-container {
  position: relative;
}

.read-only-video-container .video-js.vjs-fluid {
  width: 80%;
  max-width: 80%;
  height: 0;
  left: 30%;
  top: -30px;
}

.close-modal {
  position: absolute;
  right: 0px;
  z-index: 3;
  top: -25px;
}

.marker-research-group {
  position: absolute;
  bottom: 0px;
  left: 0px;
  font-size: 10px;
}

.data-fetching-loader-message {
  color: #fff;
}

/** New data querying prototype **/
.mediate-project-data-query-schema-container {
  padding: 10px;
  margin-top: 20px;
  border: 1px solid #3e4452;
  border-radius: 5px;
}

.mediate-project-data-query-accordion {
  margin-top: 30px;
  flex: 0.7 1;
  margin-bottom: 0px !important;
  background: #13171f !important;
  overflow-y: hidden;
}

.mediate-project-data-query-container {
  display: flex;
  height: 100%;
  overflow-y: hidden;
}

.mediate-project-data-query-results-container {
  flex: 0.3 1;
  margin-top: 0px !important;
  background: #0e1218 !important;
}

.mediate-project-data-query-heading {
  padding-bottom: 10px !important;
  border-bottom: 1px solid #3e4452 !important;
}

.mediate-project-data-query-accordion-title {
  color: #fff !important;
}

.mediate-project-data-query-scroll-container {
  position: relative;
  height: 95%;
  padding-right: 30px;
}

.mediate-project-data-query-dropdown {
  background-color: #0e1218 !important;
  border: 1px solid #3e4452 !important;
}

.mediate-project-data-query-dropdown input {
  color: #fff !important;
}

.mediate-project-data-query-dropdown > * .message {
  color: #fff !important;
}

.mediate-project-data-query-dropdown .menu {
  background: #0e1218 !important;
  border-color: #3e4452 !important;
}

.mediate-project-data-query-dropdown > .dropdown.icon {
  color: #fff;
}

.mediate-project-data-query-dropdown .menu .item {
  color: #fff !important;
  border-top: 1px solid rgb(55, 55, 55) !important;
}

.mediate-project-data-query-label-heading {
  color: #fff !important;
}

.mediate-project-data-query-button-container {
  margin-top: 20px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mediate-project-data-query-loader {
  margin-left: 20px !important;
}

.mediate-project-data-query-overview {
  display: flex;
  justify-content: center;
}

.mediate-project-data-query-format-container {
  padding-top: 20px;
  display: flex;
  justify-content: center;
}

.mediate-project-data-query-format-container .checkbox:nth-child(1) {
  margin-right: 15px !important;
}

.mediate-project-data-query-format-container label {
  color: #fff !important;
}

.mediate-project-data-query-format-label {
  padding-top: 20px;
  display: flex;
  text-align: center;
  font-style: italic;
}

html {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.app-marker-list-container {
  /*position: fixed;*/
  flex: 0.3 1;
  word-wrap: break-word;
  overflow-y: auto;
  /*width: 30%;*/
  background: #131820;
  position: relative;
  padding-left: 0;
  scroll-behavior: smooth;
  margin-top: 0;
  height: 100%;
}

/*** NEW CLASSES FOR CLASSLESS CONTAINING ELEMENTS ***/
.mediate-root-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.mediate-ui-container {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  position: relative;
  background: #0e1218;
  height: 100%;
  overflow: hidden;
}

/** MEDIATE PAGE NAV CONTAINER **/
.mediate-root-container .nav-container {
  flex: 0.1 1;
  height: auto;
}

.mediate-main-container {
  display: flex;
  width: 100%;
  position: relative;
  flex: 1 1;
  justify-content: flex-start;
  align-items: stretch;
  height: 100%;
  overflow: hidden;
}

.mediate-ui {
  position: relative;
  width: 100%;
  height: 100%;
}

.mediate-marker-container {
  width: 100%;
  flex: 0.3 1;
  overflow: hidden;
  position: relative;
  min-height: 0;
  max-height: 100vh;
}

.mediate-video-container {
  display: flex;
  width: 100%;
  flex: 0.7 1;
  position: relative;
}

.video-player-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.app-video-container {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  height: 100%;
  width: 100%;
}

.mediate-basic-video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.mediate-video-player {
  flex: 0.7 1;
  position: relative !important;
  padding-top: 1% !important;
  min-width: 0;
  width: 100%;
}

/*** Modified Add Marker Menu ***/

.add-marker-container > * {
  margin: 0px 5px !important;
}

.mediate-filter-marker-dropdown {
  background: #45505f !important;
  position: absolute !important;
  top: 50px;
}
.mediate-filter-marker-dropdown.show {
  opacity: 1;
  max-height: 300px;
  min-width: 300px !important;
  max-width: 500px !important;
  display: inline-block !important;
  transition: all 0.5s ease-in-out !important;
}

.mediate-filter-marker-dropdown.hide {
  opacity: 0;
  max-height: 0px;
  max-width: 0px !important;
  display: inline-block !important;
  transition: all 0.5s ease-in-out !important;
  margin: 0 !important;
  padding: 0 !important;
}

/**** Filtering ****/
.mediate-filter-marker-dropdown.hide .icon {
  display: none;
}

.mediate-schema-filter-container {
  background: #000;
  position: absolute !important;
  top: 0px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23) !important;
}

.schema-group-filter-container {
  margin: 10px;
}

.schema-group-filter-color {
  width: 15px;
  height: 15px;
  margin-right: 5px;
  border-radius: 10px;
  cursor: pointer;
}

.schema-group-filter-header {
  color: #fff !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.schema-group-filter-toggle-icon {
  font-size: 18px !important;
  cursor: pointer;
  position: absolute;
  right: 0;
  margin-right: 20px !important;
}

.schema-group-filter-list {
  margin: 0px 0px 0px 6px;
  padding: 0px 10px;
  transition: max-height 500ms ease-in-out;
  overflow-x: hidden;
  background: #0e1218;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.schema-group-filter-list.show {
  max-height: 1000px;
}

.schema-group-filter-list.hide {
  max-height: 0px;
}

.schema-group-filter-item {
  color: #fff;
  margin: 10px 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
}

.mediate-ui-info.show {
  border-bottom: 1px solid #444;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.mediate-ui-info.hide {
  width: auto;
}

.mediate-ui-info-container {
  color: #fff;
  align-self: center;
  position: absolute !important;
  top: 0px;
  left: 0;
  background: rgba(15, 19, 24, 0.85);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 120px;
  transition: max-width 500ms ease-in-out;
  width: 100%;
  transition: opacity 500ms ease-in-out;
}

.mediate-ui-info-tabs {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.mediate-ui-info-pane {
  background: none !important;
  border: none !important;
  position: relative;
  height: 100% !important;
  overflow: hidden !important;
  padding: 5px 10px !important;
}

.mediate-ui-info-scroll {
  height: 100%;
}

.mediate-ui-info-container > * .menu {
  background: #0f1318 !important;
  margin-bottom: 0px !important;
}

.mediate-ui-info-container > * .active.item {
  background: #2e3542 !important;
}

.mediate-ui-info-container > * .item:hover {
  background: #2e3542 !important;
}

.mediate-ui-info-label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.mediate-ui-info-toggle-icon {
  cursor: pointer;
}

.mediate-ui-info-value {
  display: flex;
  flex-wrap: wrap;
  transition: max-height 500ms ease-in-out;
  overflow: hidden;
}

.mediate-ui-info-value.show {
  max-height: 200px;
}

.mediate-ui-info-value.hide {
  max-height: 0px;
}

.mediate-ui-info-current-marker-types-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin: 5px;
}

.mediate-ui-info-current-marker-types-list .mediate-schema-active-marker {
  font-size: 12px;
  padding: 3px;
  border-radius: 3px;
}

.mediate-ui-info-container.hide {
  opacity: 0;
}

.mediate-ui-info-container.show {
  opacity: 1;
}

/* DEFAULT SCROLLBAR*/

/* Let's get this party started */
.app-marker-list-container::-webkit-scrollbar {
  width: 12px;
}

/* Track */
.app-marker-list-container::-webkit-scrollbar-track {
  /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
  /*-webkit-border-radius: 10px;*/
  /*border-radius: 10px;*/
  background: black;
}

/* Handle */
.app-marker-list-container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /*background: rgba(96,96,96,0.8);*/
  background: rgba(115, 133, 159, 0.6);
  /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);*/
}
.app-marker-list-container::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(115, 133, 159, 0.3);
}

.app-marker-list-container .ui.dropdown .menu > .header {
  margin: 8px 0 0 0;
  padding: 5px;
  /* border: none; */
  /*background: #21252c;*/
  /*color: white;*/
}

.app-marker-list-container ul {
  /*padding: 45px 0 0 0;
    margin: 0 0 0 70px;*/
}
.app-marker-list-container ul .group {
  padding: 0;
  margin: 0;
}
.app-marker-list-container .marker {
  position: relative;
  min-height: 80px;
  list-style-type: none;
  margin: 0;
  padding: 0px;
  /*border-left-style: solid;
    border-left-width: 4px;*/
  border-top: 1px solid rgba(0, 0, 0, 0.4);
  cursor: pointer;
  display: flex;
}
.app-marker-list-container .marker.repeated {
  border-top: 0px;
}
/** TODO set class on previous sibling somehow to put color bars together and remove bottom border**/
.app-marker-list-container .marker.repeated .marker-color-bar {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.app-marker-list-container .marker.sibling-repeated .marker-color-bar {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.app-marker-list-container .marker:focus {
  background: rgba(0, 0, 0, 0.2);
  outline: 3px solid #45505f;
}
.app-marker-list-container .marker-filter-container .ui.dropdown .menu {
  /*background: #45505f;*/
  z-index: 50000000;
  border-radius: 0 0 5px 5px !important;
  /*border: 1px solid white;*/
  margin-top: 0 !important;
  width: 80%;
  cursor: pointer;
}

.app-marker-list-container
  .marker-filter-container
  .ui.fluid.dropdown
  > .dropdown.icon {
  float: right;
  color: white;
  font-size: 13px;
}
/*.credits-marker {border-left-color: #9ee965;}
.shot-marker    {border-left-color: #29acff;}
.beat-marker    {border-left-color: #ffea00;}
.scene-marker   {border-left-color: #f94a6f;}*/
.credits-marker {
  background-color: #9ee965;
}
.shot-marker {
  background-color: #29acff;
}
.beat-marker {
  background-color: #ffea00;
}
.scene-marker {
  background-color: #f94a6f;
}

.marker-color-bar {
  width: 4px;
  border-radius: 5px;
  left: 4px;
  top: 4px;
  bottom: 4px;
  /*background-color: #9ee965;*/
}

.marker.shot {
  padding: 0;
  border: 0;
  /*overflow: hidden;*/
}

.ui.multiple.dropdown {
  background: #3e4452;
  width: 100%;
}

.app-marker-list-container .ui.multiple.dropdown > .label {
  padding: 10px 20px;
  border: none;
  box-shadow: none;
  position: relative;
  width: 100%;
  border-radius: 5px;
  /*border-left: 3px solid;*/
}
.app-marker-list-container .ui.label > .delete.icon {
  position: absolute;
  right: 15px;
}

.app-marker-list-container
  .marker-filter-container
  .ui.fluid.multiple.search.selection.dropdown {
  width: 100%;
  position: static;
  background: #45505f;
}
.app-marker-list-container .ui.multiple.dropdown > a:first-of-type {
  margin-top: 10px;
}
.app-marker-list-container i.options.icon {
  background: black;
  padding: 5px 10px;
  width: auto;
  height: auto;
  border-radius: 5px;
}

/*.app-video-container {
    position: absolute;
    top: 50px;
    bottom: 0;
    left: 30%;
    width: 70%;
}*/
.marker-headshot {
  position: relative;
  width: auto;
  border: none;
  color: #fff;
  margin-bottom: 2px;
}

.marker-headshot
  .mediate-collaborator-image-container
  > *
  .mediate-collaborator-image-title {
  font-size: 12px;
  font-weight: normal;
  color: #fff !important;
}

.marker-headshot
  .mediate-collaborator-image-container
  > *
  .mediate-collaborator-image-subtitle {
  font-size: 10px;
  color: #fff !important;
}

@media only screen and (max-width: 875px) {
  .marker-headshot
    .mediate-collaborator-image-container
    .mediate-collaborator-image-title-container {
    display: none;
  }
}

.marker-headshot .ui.headshot.avatar.image:hover {
  opacity: 0.8;
}

.app-marker-list-container .marker-timecode {
  position: relative;
  flex: 0.3 1;
  /*left: -70px;*/
  text-align: center;
  /*top: 0;*/
  bottom: 0;
  right: 0;
  padding: 5px;
  margin: 0px;
  background: rgba(0, 0, 0, 0.7);
  /*width: 70px;*/
  font-size: 14px;
  /*font-weight: bold;*/
  color: rgba(157, 165, 181, 1);
}

.app-marker-list-container .marker-timecode.repeated {
  position: relative;
  /*left: -70px;*/
  text-align: center;
  /*top: 0;*/
  bottom: 0;
  right: 0;
  padding: 5px;
  margin: 0px;
  background: rgba(0, 0, 0, 0.7);
  /*width: 70px;*/
  font-size: 14px;
  /*font-weight: bold;*/
  color: rgba(157, 165, 181, 1);
}

.app-marker-list-container .marker-timecode.group {
  display: block;
  position: absolute;
  bottom: 0;
  /*margin-left: 5px;*/
}
ul .group .marker-timecode {
  display: none;
}
.group {
  position: relative;
}
.app-marker-list-container .marker-type {
  position: relative;
  margin: 10px 0px 0px 10px;
  flex: 1 1;
  font-size: 15px;
  color: rgba(255, 255, 255, 1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.marker-note-icon {
  padding-left: 10px;
}

.app-marker-list-container .marker-timecode .marker-timecode-o {
  color: rgba(157, 165, 181, 0.5);
}

.app-marker-list-container .marker-timecode-bar {
  width: 5px;
  background: rgb(69, 80, 95);
  border-radius: 5px;
  height: 30px;
  position: absolute;
  z-index: 5;
  left: -13px;
  top: 27px;
  height: 80%;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
}

.app-marker-list-container .marker-shot-image {
  width: 100%;
  height: auto;
}

.marker-note {
  margin: 26px 19px 0 10px;
  color: #e8e1b8;
  padding: 5px 10px;
  margin-bottom: 30px;
  font-size: 24px;
  line-height: 1.2;
}

.shot-marker .marker-note {
  margin: -66px 19px 10px 0;
  color: #e8e1b8;
  padding: 5px 10px;
  font-size: 14px;
}
.marker-edit {
  position: absolute;
  right: 5px;
  top: 8px;
}
.marker-edit .ui.icon.top.right.pointing.dropdown.button {
  padding: 3px 1px;
  background: black;
  color: white;
  border: 2px solid rgba(255, 255, 255, 0.3);
}
.marker-edit .ui.icon.top.right.pointing.dropdown.button:hover {
  border: 2px solid rgba(255, 255, 255, 0.9);
}
/*.note-input {
  width: 50%;
  position: relative;
  margin-left: 5%;
}
.note-input > .ui-form .field .ui-input input[type="text"] {
  background-color: #000;
}*/
.no-markers {
  display: none;
  color: rgba(157, 165, 181, 1);
  font-size: 15px;
  padding: 30px 20px;
  text-align: center;
  width: 280px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.shortcut-keys {
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 700;
  background: #21252c;
  color: white;
  border-radius: 3px;
  padding: 2px 4px;
  margin: 0 0 0 5px;
}
.marker-filter-container .shortcut-keys {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  height: 18px;
  margin: auto;
  right: 8px;
  color: #6a717c;
}
.scrolling.menu .shortcut-keys {
  color: white;
  background: gray;
}
.marker-filter-container i.filter.icon {
  margin-left: -53px !important;
  color: #9da5b5;
}
/*.marker-filter-container .text {
    margin-left: -41px;
}*/
.app-marker-list-container > * li:hover {
  background: rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.app-marker-list-container .ui.dropdown .menu > .input {
  margin: 0;
}
.marker-filter-container .ui.input input {
  border: none;
}
.marker-filter-container {
  background: #171a1f;
  padding: 5px;
  z-index: 5000;
  /*width: 29%;
    top: 0;
    left: 0;
    border: 1px solid;
    height: 50px;
    position: fixed;*/
}
.marker-filter-container a.ui.label.transition.visible {
  background: black;
  color: white;
}
.app-marker-list-container .ui.floating.dropdown.labeled.icon.button {
  width: calc(100% - 80px);
  position: relative;
  right: 9px;
  margin: 0 0 0 74px;
}
.app-marker-list-container .ui.fluid.multiple.search.selection.dropdown {
  /*width: calc(100% - 80px);*/
  position: relative;
  left: 0;
  /*margin: 0 0 0 74px;*/
}
.marker-edit,
.marker-headshot {
  transition: all 0.2s ease;
  opacity: 0;
}
li:hover > .marker-edit,
li:hover > * .marker-headshot,
li:focus > .marker-edit,
li:focus > .marker-headshot {
  opacity: 1;
}

i.icon.placeholder-image {
  position: relative;
  margin: 0 auto;
  display: block;
  top: 50%;
  width: 10%;
  height: auto;
}
.marker-headshot .collaborator-icon {
  position: absolute;
  bottom: 0;
  left: 20px;
  margin: 10px;
}
/*.marker-timecode:hover + .marker.shot-marker,
.marker-timecode:hover + .marker.shot-marker {
    cursor: pointer;
    background-color: red!important;
    color: blue!important;
    border: 1px solid yellow;
}*/

/* Add Marker Button */
.add-marker-container {
  position: fixed;
  top: 5px;
  /*left: 30%;*/
  z-index: 5000;
  overflow-x: show;
  /*width: 60%;*/
  margin: 5px;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
}

/*
  .add-marker-container .ui.fluid.search.selection.dropdown {
    position: relative;
    top: 0px;
    left: 0px;
    background: #29acff;
    color: white;
    max-width: 300px;
  }
*/

.add-marker-container .add-marker-button {
  position: relative;
  display: inline-block;
  margin-left: 5px;
}

.add-marker-container .mediate-shared-link-url.show {
  opacity: 1;
  transition: opacity 0.5s ease;
}

.add-marker-container .mediate-shared-link-url.hide {
  opacity: 0;
  transition: opacity 0.5s ease;
}

.add-marker-container .mediate-shared-link-url {
  color: #fff;
}

/* VIDEO PLAYER  */
.video-js {
  font-size: 15px !important;
}

.video-js .vjs-control-bar {
  background-color: transparent !important;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(43, 51, 63, 0.55) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#8c2b333f',GradientType=0 );
}

/*.vjs-remaining-time.vjs-time-control.vjs-control {
    position: absolute;
    bottom: -67px;
    left: 20px;
    right: 20px;
    background: black;
    border-radius: 5px;
    position: absolute;
    left: 0;
    top: 50px;
    height: auto;
    width: 100%;
    border-radius: 5px;
}*/
.vjs-remaining-time-display {
  font-family: lato;
  font-weight: 300;
  /*color: #999fa9;*/
}

.marker-status {
  position: absolute !important;
  width: 30% !important;
  background: rgba(15, 19, 24, 0.85) !important;
  text-align: center;
  padding: 5px;
  height: auto;
  margin: 10px !important;
  color: #fff !important;
  top: 120px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
}

/*.marker-status.pending {
  opacity: 0 !important;
}

.marker-status i.icon {
  font-size: 40px;
  position: relative;
  top: 10px;
  border: none;
}*/

/* Mouse Hover*/
.video-js .vjs-progress-control .vjs-mouse-display {
  font-family: lato;
  z-index: 500 !important;
  outline: 1px solid rgba(255, 255, 255, 0.5);
}

/** Captions Settings **/
.vjs-tracksettings select {
  color: #000;
}

.vjs-caption-settings {
  background-color: rgba(34, 38, 44, 0.75);
  border-radius: 5px;
  font-family: lato, sans-sans-serif;
}

/*** NEW SCHEMA MODAL ***/
.mediate-schema-sidebar-dimmer {
  display: none;
}

.mediate-schema-sidebar-dimmer.active {
  position: absolute;
  display: block;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  cursor: pointer;
}
.mediate-schema-sidebar.hide {
  height: 0;
}
.mediate-schema-sidebar.show {
  height: 70vh;
}
.mediate-schema-sidebar {
  background: #131820;
  position: absolute;
  z-index: 10;
  bottom: 0;
  width: 100%;
  transition: height 500ms ease-in-out;
  box-shadow: 0 0px 12px rgba(0, 0, 0, 0.36), 0 0px 12px rgba(0, 0, 0, 0.43) !important;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}

.mediate-schema-sidebar-active-markers-container {
  flex: 0.2 1;
  min-height: 65px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #2e3542;
}

.mediate-schema-sidebar-active-markers-list,
.mediate-schema-sidebar-active-markers-list > * .simplebar-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
}

.mediate-schema-sidebar-active-marker-count {
  color: #fff;
  font-size: 18px;
  text-align: center;
  margin: 10px;
}

.mediate-schema-active-marker {
  padding: 5px;
  border-radius: 5px;
  margin: 5px;
}

.mediate-marker-shortcut-remove {
  border: none !important;
  box-shadow: none !important;
}

.mediate-schema-sidebar .mediate-schema-container {
  flex: 1 1;
  min-height: 0;
}

.mediate-schema-sidebar-header {
  color: #fff !important;
  margin: 20px !important;
}

/*** Shortcuts ***/
.mediate-create-shortcut-container {
  width: 100%;
  background: #131820;
  color: #fff;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 2px;
}

.mediate-create-shortcut-input {
  background: #000;
  padding: 5px;
  margin: 5px 0px;
  border-radius: 2px;
}

.mediate-create-shortcut-input:focus {
  outline: 1px solid teal;
}

.mediate-create-shortcut-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.mediate-create-shortcut-action {
  margin-right: 10px !important;
  cursor: pointer;
  font-size: 16px !important;
  opacity: 1;
}

.mediate-create-shortcut-action:hover {
  opacity: 0.6;
}

.mediate-marker-shortcut-label {
  background: #000;
  margin: 5px;
  padding: 5px;
}

.mediate-marker-shortcut-labels-container {
  margin: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  flex-wrap: wrap;
}

.mediate-marker-shortcut-label-detailed {
  font-size: 12px;
  padding: 0px 0px 0px 5px;
  margin: 5px;
  position: relative;
  display: flex;
  align-items: baseline;
  border-radius: 3px;
}

.mediate-marker-shortcut-label-shortcut {
  background: #2e3542;
  margin-left: 5px;
  height: 100%;
  padding: 3px 5px 3px 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.annotate-in-mediate-button {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 2;
}
/* MEDIA QUERIES */
/*@media only screen and (min-width: 800px) {
    .video-player-container{
        position: absolute;
        left: 0;
        right: 0;
        margin: auto !important;
        width: 800px;
        margin: 5%;
    }

}*/

/*.app-video-container {
  display: flex;
  align-self: center;
  flex-direction: column-reverse;
  justify-content: space-between;
  height: 90%;
  width: 100%;
  max-width: 1000px;
}*/

.mediate-player-seekbar {
  flex: 0.3 1;
  padding-left: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.mediate-player-seekbar-canvas {
  width: 95%;
  height: auto;
  position: relative;
  cursor: pointer;
  padding: 10px;
}

.mediate-player-seekbar-container {
  width: auto;
  overflow-x: hidden;
  height: auto;
  border-top: 2px solid #22262c;
  display: flex;
	background:      -o-linear-gradient(top, #303030 0%,#292929 100%);
  justify-content: center;
  -webkit-user-drag: none;
  max-height: 125px;
  min-height: 100px;
}

/*** SEEK BUTTONS ***/

.mediate-player-seekbar-controls-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
	background:      -o-linear-gradient(bottom, #303030 0%,#222 100%);
}

.mediate-seek-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
}

.mediate-player-seekbar-controls {
  color: #ccc;
  margin-right: 5px;
}

.mediate-seek-button {
  color: #ccc;
  cursor: pointer;
  margin-right: 20px;
}

.mediate-seek-rate {
  color: #ccc;
}

.mediate-seek-rate.show {
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.mediate-seek-rate.hide {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

/*** MODAL CONTAINER ***/
.mediate-marker-modal-select-container {
  margin-left: 5px;
}
.mediate-marker-modal {
  z-index: 5001 !important;
}

.mediate-marker-modal .content {
  width: 100%;
  height: 100%;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
}

.mediate-marker-modal .content .center {
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  background-color: #242422;
  max-height: 1024px;
}

/*** Marker Filter ***/

.mediate-marker-modal-select-filter-header {
  font-weight: 200;
}

.mediate-marker-modal-select-filter-container {
  align-self: flex-start;
  margin: 10px;
  background-color: #303030;
  padding: 10px;
  width: 100%;
  display: flex;
  align-items: center;
}

.mediate-marker-modal-select-filter-container .mediate-ui-widget-label {
  margin: 0px 10px;
}

.mediate-marker-modal-select-filter-input {
  flex: 0.4 1;
}


.mediate-marker-modal-select-filter-input input {
  background-color: #3f3f3f !important;
}

/*** Current Markers ***/

.mediate-marker-modal-select-current-markers-container {
  display: flex;
  flex-direction: column;
  flex: 0.6 1;
  min-height: 50px;
}

.mediate-marker-modal-select-current-markers {
  width: 100%;
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  min-height: 25px;
}

.mediate-marker-modal-select-current-markers-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3f3f3f;
}

.mediate-marker-modal-select-current-markers-container .mediate-ui-widget-label {
  flex: 0.2 1;
}

/*** MARKER SELECT ***/

.mediate-marker-modal-select-group {
  display: flex;
  flex-direction: row;
  align-self: center;
}

.mediate-marker-modal-select-markers {
  flex: 0.8 1;
  min-width: 250px !important;
  overflow-y: auto;
  overflow-x: hidden;
}

.mediate-marker-modal-select-close {
  position: absolute;
  right: 0;
  margin: 40px !important;
  top: 0px;
}

.mediate-marker-modal-select-marker {
  margin: 2px;
  display: flex;
  background-color: #3f3f3f;
}

.mediate-marker-modal-select-description {
  z-index: 5001 !important;
  background-color: #ccc;
}

/*** MARKER SHORTCUTS ***/

.mediate-marker-modal-shortcuts-container {
  display: flex;
  width: 100%;
  height: auto;
  flex: 0.5 1;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-color: #303030;
  border-radius: 4px;
  margin-right: 20px;
  padding: 10px 10px 20px 10px;
  overflow-y:auto;
}

.mediate-marker-modal-shortcut-slot {
  background-color: #3f3f3f;
  height: auto;
  border-radius: 3px;
  padding: 10px 0px 10px 0px;
  margin-top: 10px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  height: 60px;
  width: 150px;
}

.mediate-marker-modal-shortcut-slot-label {
  width: 100%;
  background: #2185d0;
  margin-bottom: 5px;
  align-self: flex-start;
}

.mediate-marker-shortcut-label {

}

.mediate-marker-shortcut-remove {
  margin-left: 10px !important;
  padding: 2px !important;
}

.note-thread-content {
  font-family: "Lato", sans-serif;
  color: #fff !important;
  background: #22262c !important;
  border-radius: 0 !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center;
  margin: 10%;
}

.mediate-note-modal {
  border-radius: 0 !important;
  z-index: 5001 !important;
  background-color: #22262c !important;
}

.note-thread-title {
  font-family: "Lato", sans-serif;
  color: #fff !important;
  font-weight: 100 !important;
  background: #22262c !important;
  border-bottom: 1px solid #ccc !important;
  border-radius: 0 !important;
  display: flex !important;
  align-items: center;
  padding: 25px 10px 25px 10px;
}

.note-title-text {
  font-size: 24px;
}

.note-thumbnail {
  width: 25%;
  height: auto;
  flex: 0.3 1;
}

.note-input-form {
  display: flex !important;
  align-items: center;
  flex-direction: column !important;
}

.note-submit {
  align-self: flex-end;
  margin-top: 20px !important;
}

.note-input {
  width: 90%;
  padding-top: 25px;
}

/** New basic note thread **/

.mediate-basic-note-thread-container {
  display: flex;
  flex-direction: row;
  height: 70vh;
  overflow-y: hidden;
  padding: 0 !important;
  position: relative;
}

.mediate-basic-note-thread-container .no-comments {
  align-items: stretch;
  justify-content: stretch;
}

.mediate-basic-comment-thread-group {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  overflow: auto;
  max-width: 100% !important;
  padding: 10px;
  background: #0e1218;
  flex: 1 1;
  margin-top: 10px !important;
}

.mediate-basic-comment-thread-comment-content {
  display: flex !important;
  flex-direction: column;
  max-width: 100% !important;
}

.mediate-basic-note-thread-header {
  background: #2e3138 !important;
  color: #fff !important;
  padding: 20px !important;
  font-size: 24px;
  font-weight: 600;
}

.mediate-basic-note-thread-content {
  padding: 20px;
  height: 100%;
}

.mediate-basic-note-thread-comments-header {
  color: #fff !important;
}

.mediate-basic-comment-thread-comment-author,
.mediate-basic-comment-thread-comment-text {
  color: #fff !important;
  max-width: 650px;
}

.mediate-basic-comment-thread-comment-date,
.mediate-basic-comment-form-status-label > label,
.mediate-basic-comment-thread-comment-actions > a,
.mediate-basic-comment-thread-comment-actions > .confirm-delete-container > a {
  color: #ccc !important;
}

.mediate-basic-comment-thread-comment-date {
  margin-left: 0px !important;
}

.mediate-basic-comment-thread-comment .comments {
  box-shadow: -1px 0 0 rgb(46 49 56) !important;
  margin-bottom: 10px !important;
}

.mediate-comments-thread-circular-avatar {
  display: block;
  width: 2.5em;
  height: 100%;
  float: left;
  margin: 0.2em 0 0;
}

.mediate-comments-thread-circular-avatar ~ .content {
  margin-left: 3.5em;
}

.mediate-basic-comment-thread-comment-status {
  padding-left: 10px;
}

.mediate-basic-note-thread-form {
  flex: 1 1;
  margin-right: 20px;
}

.mediate-basic-note-thread-form > .mediate-basic-comment-thread-textarea {
  max-width: 100%;
}

.mediate-basic-note-thread-form .button {
  margin-top: 10px !important;
}

.mediate-basic-comment-thread-textarea {
  margin: 10px 0px !important;
  max-width: 750px;
}

.mediate-basic-comment-thread-textarea textarea {
  color: #fff !important;
  padding: 5px;
  border-radius: 2px;
  background: #2e3138 !important;
  width: 100%;
  resize: none !important;
  border: none;
  font-family: "Lato", "Helvetica", "Arial";
  outline: none !important;
  margin: 0.25em 0 0.5em;
  font-size: 1em;
  word-wrap: break-word;
  color: rgba(0, 0, 0, 0.87);
  line-height: 1.3;
}

.mediate-basic-comment-thread-textarea:focus {
  border-color: none !important;
}

.mediate-basic-comment-thread-replies {
  display: block;
}

.mediate-basic-comment-thread-replies.hide {
  flex: 0 1;
}

.mediate-basic-comment-thread-replies-toggle {
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px 0px;
}

.mediate-basic-comment-thread-replies {
  max-width: 100% !important;
}

.mediate-basic-comment-thread-add-new-container {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  z-index: 2;
}

.mediate-admin-container {
  background: #fff;
  height: 100%;
  overflow-y: hidden;
  position: relative;
}

.mediate-admin-root {
  height: 95vh;
  width: 100%;
  overflow-y: hidden;
  padding-bottom: 20px;
}

/*** PROJECT OVERVIEW ***/
.mediate-project-overview-container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
}

.mediate-project-overview-header-container,
.mediate-basic-asset-display-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  border-bottom: 2px solid #e4e5e8;
  padding: 10px;
  flex-wrap: wrap;
}

.mediate-basic-asset-filter {
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: stretch;
}

.mediate-basic-asset-filter-icon {
  color: #ccc !important;
}

.mediate-basic-asset-filter .input {
  flex: 1 1;
  margin-left: 10px;
}

.mediate-project-overview-header {
  margin-bottom: 0px !important;
  color: #000;
}

.mediate-project-overview-column,
.mediate-admin-all-data-container {
  height: 100%;
}

.mediate-admin-all-data-row {
  flex: 0.5 1;
  overflow: hidden;
}

.mediate-project-overview-row {
  height: 100%;
}

.mediate-admin-notification {
  overflow: hidden !important;
  height: 100%;
}

.mediate-projects {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 10px;
  overflow: auto;
}

.mediate-project-display {
  margin-top: 20px;
  flex: 1 1;
  background: #f0f0f0 !important;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.mediate-project-title {
  color: #000 !important;
  width: 100%;
  background: #e4e5e8;
  padding: 20px;
  border-radius: 5px 5px 0px 0px;
  margin-bottom: 0px;
  opacity: 1;
  transition: opacity 0.25s ease-in-out;
}

.mediate-project-title:hover {
  opacity: 0.7;
}

.mediate-project-description {
  color: #8c909e;
  padding: 0px 0px 0px 15px;
}

.mediate-project-options {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
}

.mediate-project-option-button {
  cursor: pointer;
  margin: 5px !important;
}

/*** Labels ***/
.mediate-admin-label-inverted {
  background: #828489 !important;
  color: #fff !important;
}

/*** Collaborators ***/
.mediate-project-social-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
}
.mediate-project-private-notification {
  min-height: 0;
  flex: 0.25 1 !important;
}

.mediate-project-private-notification-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-x: hidden;
  position: relative;
  height: 100%;
}

.mediate-project-private-notification-status,
.mediate-project-private-notification-message {
  margin: 10px 0px !important;
  color: #000;
}

.mediate-collaborator-display-container {
  min-height: 0;
  flex: 0.6 1 !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
}
.mediate-project-collaborators-container {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  width: 100%;
  padding: 10px 0px 10px 0px;
  margin-bottom: 10px;
}
.mediate-project-collaborators {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 0px 0px 0px 5px;
}

.mediate-project-collab-list {
  height: 100%;
}

.mediate-project-collab-options {
  overflow: hidden;
}

.mediate-collaborator-display-text {
  color: #8c909e;
  margin-top: 0px;
  padding: 0px 0px 0px 5px;
}

.mediate-collaborator-icon {
  width: auto;
  display: flex;
  flex-wrap: wrap;
}

.mediate-collaborator-image-container {
  display: flex;
  align-items: center;
  min-width: 0;
}

.mediate-collaborator-image-title-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.mediate-collaborator-image-title {
  margin-left: 5px;
  font-size: 16px;
  font-weight: bold;
  color: #000;
}

.mediate-collaborator-image-subtitle {
  margin: 0px 0px 0px 5px !important;
  font-size: 12px;
}

/*** Individual Project View ***/
.mediate-project-title-large {
  font-size: 28px;
  margin: 5px !important;
}

.mediate-project-view-root {
  width: 100vw;
  height: 100vh;
}

.mediate-project-view-grid {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  height: 100%;
}

.mediate-project-display-right,
.mediate-project-display-left {
  margin: 20px;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.mediate-project-display-left {
  flex: 0.6 1 !important;
}

.mediate-project-display-right {
  flex: 0.4 1 !important;
}

.mediate-project-tab {
  padding: 30px;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #000;
}

.mediate-form-tabs-container {
  height: 90% !important;
  overflow-y: hidden;
}

.mediate-form-tabs-header {
  color: #fff;
  background: #2e3442;
  margin-bottom: 0px !important;
  padding: 20px;
  border-radius: 5px 5px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.mediate-form-tabs-header-placeholder {
  width: 120px;
}

.mediate-form-tabs-header-title {
  margin: 0 !important;
}

.mediate-admin-subheader {
  margin: 5px 0px 0px 0px !important;
}

.mediate-form-tabs-item {
  padding: 10px !important;
  margin: 0px !important;
}

.mediate-form-tabs-item-button {
  background: #131720 !important;
  color: #fff !important;
}

.mediate-form-tabs-active-tab {
  position: relative;
  width: 100%;
  height: 100%;
}

.mediate-form-tabs-active-tab > * .mediate-project-overview-header,
.mediate-form-tabs-active-tab > * .mediate-search-result-info,
.mediate-form-tabs-active-tab > * .mediate-search-media-result-info,
.mediate-form-tabs-active-tab > * .mediate-collaborator-image-title {
  color: #fff !important;
}

.mediate-form-tabs-active-tab .active.tab {
  padding: 0px !important;
  border-radius: 0px 0px 5px 5px !important;
  height: 100%;
}

.mediate-form-tabs-menu-container > * .active.tab {
  background: none !important;
  border: none !important;
}

.mediate-project-admin-link-container {
  display: flex;
  align-items: center;
}

.mediate-project-admin-link {
  font-size: 20px;
  color: #000;
  opacity: 1;
  cursor: pointer;
  transition: opacity 250ms ease-in-out;
}

.mediate-project-admin-link:hover {
  opacity: 0.6;
  color: #000;
}
.mediate-project-meta {
  min-height: 0;
  flex: 0.2 1;
}

.mediate-project-asset-container {
  min-height: 0;
  flex: 0.4 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: hidden;
  padding-bottom: 20px;
}

.mediate-project-asset-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
  background: #e4e5e8;
  border-radius: 5px 5px 0px 0px;
  margin-bottom: 0px;
  width: 100%;
}

.mediate-project-asset-title {
  margin-bottom: 0px;
  font-size: 20px;
  color: #000;
}

.mediate-project-asset-stats {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.mediate-project-asset-created {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 10px 0px 0px 0px !important;
  font-size: 12px;
}

.mediate-project-asset-meta {
  padding: 10px;
}

.mediate-project-asset-description {
  color: #000;
}

.mediate-project-assets-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.mediate-project-asset-list {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  background: #f0f0f0 !important;
  border-radius: 0px 0px 5px 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.mediate-basic-media-display-container,
.mediate-basic-display-container {
  overflow: hidden;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.mediate-basic-list {
  position: relative;
  height: 100% !important;
  padding: 15px;
  overflow-x: hidden;
}

.mediate-basic-list > * .simplebar-content {
  height: 100%;
  position: relative;
}

.mediate-basic-display-container {
  height: 100%;
}

.mediate-basic-list.inverted {
  background: #1c242f;
  border-radius: 5px;
}

.mediate-basic-list > * .mediate-media-search-result,
.mediate-search-result {
  padding: 10px;
  cursor: pointer;
  opacity: 1;
  transition: opacity 250ms ease-in-out;
  display: flex;
}

.mediate-search-media-result-info,
.mediate-search-result-info {
  display: flex;
  flex-direction: column;
  margin-left: 2px;
  color: #000;
}

.mediate-search-media-result-owner,
.mediate-search-result-owner {
  font-size: 12px;
  opacity: 0.8;
}

.mediate-search-media-result-label {
  width: 25px !important;
}

.mediate-project-film-title {
  margin: 0px !important;
}

.mediate-project-film-marker-count {
  margin-right: 10px !important;
}

.mediate-project-film-display,
.mediate-project-asset-display {
  background: #fff;
  margin: 10px 10px 20px 10px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16), 0 1px 3px rgba(0, 0, 0, 0.23) !important;
}

.mediate-project-asset-display.inverted {
  background: #13171f;
  color: #fff;
  padding: 10px;
}

.mediate-project-asset-display.inverted > * .mediate-project-asset-title {
  color: #fff;
}

.mediate-project-options-buttons {
  position: relative;
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0px 5px 5px 5px;
  border-top: 1px solid #e4e4e8;
}

.mediate-project-asset-display.inverted > .mediate-project-options-buttons {
  border-top: 1px solid #22262c;
}

.mediate-project-writable-options-container {
  display: flex;
  align-items: baseline;
  margin-top: 10px;
}

.mediate-project-asset-display-text-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background: #e4e4e8;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.mediate-project-asset-display.inverted
  .mediate-project-asset-display-text-container {
  background: #13171f;
}

.mediate-project-film-meta {
  display: flex;
}

/*** Modals ***/
.mediate-project-sidebar-overlay {
  display: flex;
  flex-direction: column;
  background: #21252c !important;
  height: 50%;
}

.mediate-project-tabs-back-button {
  align-self: flex-end;
  cursor: pointer;
}

.mediate-confirm-delete .content {
  color: #000;
}

/*** Modal Forms ***/
.mediate-tab-form {
  margin-bottom: 20px !important;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 400px !important;
  background: #13171f !important;
  box-shadow: 0 1px 3px rgba(34, 31, 31, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24) !important;
}

.mediate-tab-form-toggle-public {
  display: flex;
  align-items: baseline;
  margin: 0px 0px 20px 0px;
}

.mediate-tab-form-toggle-public-text {
  margin-left: 20px;
}

.mediate-form-label {
  border-bottom: 1px solid #444;
  padding-bottom: 5px;
  margin-bottom: 15px !important;
}

.mediate-form-upload-info {
  margin-left: 10px;
}

.mediate-form-header {
  padding-bottom: 5px;
  margin-bottom: 15px !important;
}

.mediate-form-add-button {
  align-self: center;
  margin-top: 20px !important;
}

.mediate-form-accordion-container {
  margin-top: 30px !important;
  background: #fff;
  padding: 20px;
  border-radius: 5px;
}

.mediate-form-accordion-title {
  font-size: 20px !important;
  border-bottom: 1px solid #444;
}

.mediate-add-project-prompt,
.mediate-add-project-form-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mediate-add-project-prompt .mediate-project-title {
  background: none;
  text-align: center;
  font-size: 32px;
}

.admin-root-add-project-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.mediate-add-project-form-header {
  width: 80%;
  color: #fff;
  background: #2e3442;
  margin-bottom: 0px !important;
  padding: 20px;
  border-radius: 5px 5px 0px 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.mediate-add-project-form-title {
  margin: auto !important;
}

.mediate-add-project-form {
  width: 80%;
  background: #13171f !important;
  padding: 20px;
  border-radius: 0px 0px 5px 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.mediate-form-tabs-menu-container {
  position: relative;
  display: block;
  height: 80%;
}

.mediate-form-tabs-menu {
  background: rgb(5, 5, 5) !important;
  padding: 20px;
  border-radius: 0px 0px 5px 5px;
  position: absolute !important;
  top: 0;
}

.mediate-form-tabs-active-tab-dimmer {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 500ms ease-in-out;
}

.mediate-form-tabs-active-tab-dimmer.visible {
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 3;
}

.mediate-form-add-media-pane {
  background: none !important;
  border: none !important;
  padding: 0px !important;
  border-radius: 0px 0px 5px 5px !important;
}

.mediate-admin-link {
  color: #6a6c76;
}

.mediate-admin-link:hover {
  color: #b1b1b1;
}

.mediate-admin-project-user-asset-display {
  background: #13171f !important;
  height: 100%;
  margin: 0 !important;
}

.file-display {
  cursor: pointer;
  margin: 2px !important;
}

.file-display:hover {
  opacity: 0.8;
}

.mediate-form-upload-info {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
}

.mediate-form-file-field-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

/*** ADMIN NOTIFICATIONS ***/
.admin-notification-children {
  width: 100%;
  height: 100%;
  position: relative;
  display: block;
}

.mediate-admin-notification-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  border-bottom: 1px solid #ccc;
}

.mediate-admin-notification-sidebar {
  min-height: 15vh;
  width: 200px !important;
  color: #21252c !important;
}

.mediate-admin-notificaton-close-button {
  cursor: pointer;
  align-self: flex-start;
}

.actions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: baseline;
}

.mediate-admin-actions-button:first-child {
  margin-left: 0px !important;
}

/*** Collaborators ***/

/*** Schema ***/
.mediate-schema-container {
  margin-top: 20px;
  height: 100%;
}

.mediate-marker-type-count-label {
  width: 25px;
  height: 25px;
  margin-right: 5px;
  background-color: #28adff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  color: #fff;
}

/*** Form validation errors ***/
@-webkit-keyframes error {
  0% {
    border: 1px solid rgba(0, 0, 0, 0);
  }
  50% {
    border: 1px solid rgba(255, 0, 0, 1);
  }
  100% {
    border: 1px solid rgba(0, 0, 0, 0);
  }
}
@keyframes error {
  0% {
    border: 1px solid rgba(0, 0, 0, 0);
  }
  50% {
    border: 1px solid rgba(255, 0, 0, 1);
  }
  100% {
    border: 1px solid rgba(0, 0, 0, 0);
  }
}
.has-error {
  -webkit-animation-name: error;
          animation-name: error;
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

/*** Upload Info ***/
.media-upload-info-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 5px 0px;
  border-radius: 5px;
  width: auto;
}

.media-upload-info-trigger {
  cursor: pointer;
}

.media-upload-info-detail-card {
  position: absolute !important;
  right: 0px !important;
  bottom: 0px !important;
  margin: 35px !important;
  height: 200px;
  box-shadow: 0 6px 9px rgba(0, 0, 0, 0.36), 0 6px 9px rgba(0, 0, 0, 0.43) !important;
  z-index: 10;
  overflow: hidden;
}

.media-upload-info-detail-card .content {
  overflow: hidden;
  height: 100%;
}

.media-upload-info-detail-card .content .description {
  height: 90%;
  overflow: hidden;
}

.media-upload-info-detail-header {
  padding-bottom: 5px;
  margin-bottom: 3px;
  border-bottom: 1px solid #ccc;
}

.media-upload-info-detail-list {
  position: relative;
  height: 95% !important;
  overflow-x: hidden;
  padding: 15px;
}

.media-upload-info-detail-list > * .simplebar-content {
  height: 100%;
  position: relative;
}

/*** Forms ***/
.mediate-admin-search-form {
    background: #13171F !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    overflow-y: hidden;
    margin-top: 0px !important;
}

.mediate-admin-search-form.loading::before {
    background-color: rgba(0, 0, 0, 1) !important;
}

.mediate-live-search-text-input .input input {
    color: #fff !important;
}

/*** Search Results ***/

.mediate-search-results-display {
    height: 100%;
}

.mediate-search-result-detail-card {
    border: none !important;
}
.mediate-search-media-result-date {
    padding: 2px;
}

.mediate-live-search-results {
    min-height: 0;
    position: relative;
    background: #1c242f;
    border-radius: 5px;
    padding: 15px;
    flex: 1.5 1;
}

.mediate-live-search-scroll {
    height: 100%;
    overflow-x: hidden;
}

.mediate-live-search-results-grid {
    height: 100%;
}

.mediate-live-search-input {
    min-height: 0;
    padding: 10px;
    height: 32px;
}

.mediate-search-result-overview-image {
    padding: 0px 10px 0px 10px !important;
}

.mediate-search-result-video-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start
}

.mediate-search-result-back-button {
    position: absolute !important;
    z-index: 2;
    margin: 10px !important;
}

.mediate-media-search-result, .mediate-search-result {
    padding: 5px 0px !important;
    cursor: pointer;
    opacity: 1.0;
    transition: opacity 250ms ease-in-out;
    display: flex;
    align-items: center;
}

.mediate-media-search-result:hover, .mediate-search-result:hover {
    opacity: 0.6;
}
.user-account-container {
    width: 100%;
    height: 100%;
    background: #fff;
}

.mediate-account-section-profile-container {
    display: flex !important;
    justify-content: center;
    align-items: center;
}

/*** Cards ***/

.user-account-profile-card > .mediate-form-file-field {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.change-profile-image-button {
    cursor: pointer;
}

.change-profile-image-button:hover {
    opacity: 0.8;
}

.mediate-user-card-modal-content {
    display: flex !important;
    justify-content: center;
    align-items: center;
    background-color: #fff !important;
}

.mediate-user-card-modal-trigger-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    overflow: hidden;
    flex-wrap: wrap;
}

.mediate-collaborator-display-container > * .mediate-user-card-modal-trigger-container {
    border-bottom: 1px solid #ccc;
}

.mediate-user-card-modal-trigger {
    cursor: pointer;
    opacity: 1.0;
    transition: opacity 250ms ease-in !important;
}

.mediate-user-card-modal-trigger:hover {
    opacity: 0.7;
}

.mediate-user-card-modal-trigger-actions {
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: baseline;
}

.mediate-user-card-modal-trigger-actions > .toggle-icon-label {
    margin-right: 10px !important;
}

.mediate-collaborator-options-button {
    cursor: pointer;
}
/*** Stats ***/

.mediate-stats-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.mediate-stats-group {
    justify-content: center;
    align-items: center !important;
}

.mediate-stats-options {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 20px 10px 0px 10px;
}

.mediate-stat {
    display: flex !important;
    justify-content: center;
    flex-wrap: wrap;
}

/*** Technical Settings ***/
.mediate-tech-settings-label {
    width: 100%;
    text-align: justify;
    border-bottom: 1px solid #ccc;
    padding-bottom: 5px;
}


.mediate-schema-admin-container {
  height: 95% !important;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.mediate-schema-container {
  position: relative;
  display: flex;
  margin-top: 0px;
  height: 100%;
}

.mediate-form-add-schema-active-tab {
  height: 100% !important;
}

.mediate-schema-create-group {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  padding: 15px;
  background: #131720 !important;
}

.mediate-schema-options-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 0;
  flex: 0.3 1;
}

.mediate-schema-options-container.read-only {
  flex: 0.1 1;
  justify-content: space-between;
}

.mediate-schema-read-only-title {
  color: #fff;
  margin-bottom: 0px;
}

.mediate-schema-options {
  display: flex;
  align-items: center;
}

.mediate-schema-group-options {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mediate-schema-extra-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 20px;
}

.mediate-schema-info-trigger {
  color: #fff;
  z-index: 1001;
  cursor: pointer;
  margin-right: 15px !important;
}

.mediate-schema-info-container {
  position: relative;
  background: #fff;
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.mediate-schema-info-container .mediate-tab-form-toggle-public {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.mediate-schema-info-container > * .mediate-tab-form-toggle-public-text {
  margin: 10px 0px !important;
}

.mediate-schema-created-info {
  margin: 5px 0px !important;
}

.mediate-schema-text-field {
  color: #fff !important;
  margin-bottom: 10px;
  padding: 5px;
  border-radius: 5px;
}

.mediate-schema-text-field.read-only {
  color: #000 !important;
}

.mediate-schema-text-field textarea {
  width: 100%;
  resize: none;
  border: none;
  font-family: "Lato", "Helvetica", "Arial";
}

#mediate-schema-name-field {
  font-size: 24px;
  font-weight: bold;
}

#mediate-schema-description-field {
  font-size: 14px;
  color: #666;
}

.mediate-schema-text-field textarea:focus {
  outline-style: none;
}

.schema-group-container {
  width: 100%;
  min-height: 0;
  margin-top: 15px;
  max-height: 500px;
  min-height: 0;
  flex: 2 1;
}

.schema-group-container > * .simplebar-offset {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 0;
}

.schema-group-container.compact {
  min-height: 0;
  max-height: 145px;
  flex: 1 1;
}

.mediate-marker-search-container {
  max-height: 0px;
  opacity: 0;
  overflow: hidden;
  transition: 500ms ease-in-out;
  transition-property: max-height, opacity;
  flex: 2.5 1;
}

.mediate-marker-search-container.with-search {
  max-height: 500px;
  min-height: 0;
  opacity: 1;
  overflow: hidden;
}

.mediate-marker-search-container .mediate-admin-search-form {
  padding: 0px !important;
}

.schema-group-container > * .simplebar-content-wrapper {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.schema-group-container > * .simplebar-content {
  display: flex;
  flex-direction: row;
  min-height: 0;
  height: 100%;
  width: 100%;
  -webkit-user-select: none; /* Safari */
  user-select: none;
  position: relative;
}

.schema-group-container .simplebar-horizontal .simplebar-scrollbar::before {
  background: #fff;
}

.schema-group {
  padding: 15px;
  background: #2e3542;
  border-radius: 10px;
  display: flex !important;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 10px;
  height: 98%;
}

.schema-group.read-only {
  color: #fff;
}

.schema-group > * .mediate-admin-marker-label-read-only {
  background: #131720;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 10px;
  color: #fff;
}

.mediate-admin-marker-label-read-only-actions {
  display: flex;
  align-items: baseline;
}

.mediate-admin-marker-label-read-only-name {
  display: flex;
  justify-content: space-between;
}

.schema-group > * .simplebar-content-wrapper {
  width: 100% !important;
}

.schema-group.read-only > * .schema-group-name-field {
  opacity: 1 !important;
}

.schema-group-name-field {
  padding: 5px;
  border-radius: 5px;
}

.schema-group-name-field.error > * .prompt.label {
  position: absolute !important;
  left: 0;
  right: 0px;
}

.schema-group-read-only-color-display {
  width: 15px;
  height: 15px;
  border-radius: 10px;
  margin-right: 10px;
}

.schema-group-marker-list {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.schema-group-marker-list > * .simplebar-content {
  flex-direction: column !important;
}

.schema-group-settings {
  display: flex;
  justify-content: baseline;
  align-items: center;
}

.schema-group-name-field input {
  color: #fff !important;
  font-size: 18px;
}

.schema-group-add-marker-button {
  color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: red;
  cursor: pointer;
  padding: 5px 0px;
  border-radius: 0px 0px 10px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.schema-group-add-marker-button-text {
  background: #2e3442;
  display: flex;
  justify-content: center;
  align-items: baseline;
  padding: 4px;
  border-radius: 5px;
}

.schema-group-delete-button,
.schema-group .confirm-delete-container {
  color: #fff;
  align-self: flex-end;
  cursor: pointer;
}

.schema-group-activity {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  border-top: 1px solid #131720;
  width: 100%;
  padding-top: 5px;
}

.schema-group-markers-count {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

/*** Marker Labels ***/
.mediate-admin-marker-label {
  background: #0e1013;
  padding: 10px;
  border-radius: 5px 10px 10px 5px;
  position: relative;
  display: block;
  margin-bottom: 10px;
  overflow: auto;
  cursor: pointer;
}

.mediate-admin-marker-label-draggable {
  width: auto !important;
}

.admin-marker-label-color {
  position: absolute;
  width: 5px;
  height: 100%;
  left: 0;
  top: 0;
}

.admin-marker-label-field > * input {
  color: #fff !important;
}

.admin-marker-label-container {
  display: flex;
  justify-content: baseline;
  align-items: center;
}

.admin-marker-label-toggle-desc {
  color: #fff;
}

#admin-marker-label-description-field {
  border-top: 1px solid #666 !important;
  padding-top: 5px !important;
}

.admin-marker-label-delete {
  color: #fff;
  position: absolute;
  right: 0;
  top: 0;
  margin: 5px 5px 0px 0px !important;
  cursor: pointer;
}

.mediate-admin-marker-label-read-only {
  border-bottom: 1px solid #2e3442;
  font-size: 14px;
  padding-bottom: 5px;
  cursor: pointer;
  opacity: 1;
}
/** should move to common **/


.mediate-admin-marker-label-read-only:hover {
  opacity: 0.8;
}

.mediate-admin-marker-label-read-only-owner {
  color: #777;
}

.admin-marker-label-popup {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mediate-marker-admin-container {
  height: 100%;
}

.mediate-marker-admin {
  width: 100%;
  height: 90%;
  background: #131720;
}

.mediate-marker-actions {
  padding: 20px;
}

.mediate-basic-marker-list,
.mediate-basic-marker-list > * .simplebar-content {
  width: 100%;
  height: 100%;
}

.mediate-marker-labels-display-container {
  padding: 10px;
  width: 100%;
  height: 100%;
}

.mediate-basic-admin-marker-label {
  padding: 5px;
  background: #000;
  color: #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.mediate-admin-marker-label-title {
  margin: 0px;
}

/*** color picker ***/
.mediate-color-picker-popup-trigger {
  width: 15px;
  height: 15px;
  border-radius: 8px;
  margin-right: 5px;
  cursor: pointer;
}

/*** Search Results ***/
.mediate-schema-result-header {
  font-size: 28px !important;
}

.mediate-search-result-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mediate-schema-result-created-container {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
}

.mediate-schema-result-info-container {
  position: relative;
  display: block;
  flex: 0.7 1;
  margin-top: 5px !important;
}

.mediate-search-result-description {
  text-overflow: ellipsis;
  overflow-y: auto;
  overflow-x: hidden;
  flex: 0.3 1;
  margin-bottom: 5px;
}

/*** Stats ***/
.mediate-schema-usage-stats {
  margin-top: 10px !important;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.mediate-schema-usage-stats .mediate-admin-subheader {
  color: #000;
}

.mediate-schema-usage-stat {
  display: flex !important;
  flex-direction: row;
  align-items: center !important;
  justify-content: center !important;
  width: 100px;
  margin: 0px 5px !important;
}

.mediate-schema-usage-stat .attached.label {
  display: flex;
  justify-content: center;
}

.schema-cloned-hierarchy-node {
  margin: 10px !important;
  padding: 0px 5px !important;
  border-left: 1px solid #ccc;
}

.schema-cloned-hierarchy-image-container {
  display: flex;
  align-items: center;
}

/*** Basic Schema Display ***/
.mediate-basic-schema-display-container {
  overflow: hidden;
}
.mediate-basic-schema-display-actions {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}

.mediate-schema-result-detail-card-content {
  padding: 25px !important;
}

.mediate-basic-schema-display-actions > button {
  margin-bottom: 5px !important;
}

/*** Errors ***/
.mediate-schema-error-container {
  position: absolute !important;
  bottom: 0;
  right: 0;
}
.mediate-schema-error-prompt {
  color: #fff !important;
}

/* ----------------------------

MASTER STYLESHEET
-------------------------------
This is the master stylesheet.
It contains all styles for this
sub-theme.
-------------------------------*/

/* TABLE OF CONTENTS */

/* 1. COMMON - Global styles */

/* 2. HOMEPAGE  */

/* 3. DASHBOARD  */

/* 4. Data Query Page */

/* 5. MAIN MEDIATE ANNOTATION UI */

/* 6. SEEKBAR */

/* 7. MARKER SELECT MODAL */

/* 8. MARKER NOTE MODAL */

/* 9. ADMIN INTERFACE */

/* 10. SEARCH  */

/* 11. USER ACCOUNT */

/* 11. Schema */
