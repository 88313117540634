/*.app-video-container {
  display: flex;
  align-self: center;
  flex-direction: column-reverse;
  justify-content: space-between;
  height: 90%;
  width: 100%;
  max-width: 1000px;
}*/

.mediate-player-seekbar {
  flex: 0.3;
  padding-left: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.mediate-player-seekbar-canvas {
  width: 95%;
  height: auto;
  position: relative;
  cursor: pointer;
  padding: 10px;
}

.mediate-player-seekbar-container {
  width: auto;
  overflow-x: hidden;
  height: auto;
  border-top: 2px solid #22262c;
  display: flex;
  background: -webkit-linear-gradient(top, #303030 0%,#292929 100%);
	background:    -moz-linear-gradient(top, #303030 0%,#292929 100%);
	background:      -o-linear-gradient(top, #303030 0%,#292929 100%);
  justify-content: center;
  -webkit-user-drag: none;
  max-height: 125px;
  min-height: 100px;
}

/*** SEEK BUTTONS ***/

.mediate-player-seekbar-controls-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: -webkit-linear-gradient(bottom, #303030 0%,#222 100%);
	background:    -moz-linear-gradient(bottom, #303030 0%,#222 100%);
	background:      -o-linear-gradient(bottom, #303030 0%,#222 100%);
}

.mediate-seek-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
}

.mediate-player-seekbar-controls {
  color: #ccc;
  margin-right: 5px;
}

.mediate-seek-button {
  color: #ccc;
  cursor: pointer;
  margin-right: 20px;
}

.mediate-seek-rate {
  color: #ccc;
}

.mediate-seek-rate.show {
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.mediate-seek-rate.hide {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}
