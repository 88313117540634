/*** Forms ***/
.mediate-admin-search-form {
    background: #13171F !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    overflow-y: hidden;
    margin-top: 0px !important;
}

.mediate-admin-search-form.loading::before {
    background-color: rgba(0, 0, 0, 1) !important;
}

.mediate-live-search-text-input .input input {
    color: #fff !important;
}

/*** Search Results ***/

.mediate-search-results-display {
    height: 100%;
}

.mediate-search-result-detail-card {
    border: none !important;
}
.mediate-search-media-result-date {
    padding: 2px;
}

.mediate-live-search-results {
    min-height: 0;
    position: relative;
    background: #1c242f;
    border-radius: 5px;
    padding: 15px;
    flex: 1.5;
}

.mediate-live-search-scroll {
    height: 100%;
    overflow-x: hidden;
}

.mediate-live-search-results-grid {
    height: 100%;
}

.mediate-live-search-input {
    min-height: 0;
    padding: 10px;
    height: 32px;
}

.mediate-search-result-overview-image {
    padding: 0px 10px 0px 10px !important;
}

.mediate-search-result-video-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start
}

.mediate-search-result-back-button {
    position: absolute !important;
    z-index: 2;
    margin: 10px !important;
}

.mediate-media-search-result, .mediate-search-result {
    padding: 5px 0px !important;
    cursor: pointer;
    opacity: 1.0;
    transition: opacity 250ms ease-in-out;
    display: flex;
    align-items: center;
}

.mediate-media-search-result:hover, .mediate-search-result:hover {
    opacity: 0.6;
}