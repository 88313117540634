/* COLORS
light text - #9da5b4
dark text  - #4b5263

gray bg    - #21252b
light gray bg - #282c34


*/

@import "https://fonts.googleapis.com/css?family=Lato:100,300,400,900";

body {
  background: #21252c !important;
  color: white;
  font-family: lato;
}

#root {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* Overiding the color of the blue button */
.ui.blue.button {
  background-color: #29acff;
}

.ui.avatar.image {
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  height: 35px;
  width: auto;
}

.mediate-icon-small {
  height: 15px;
  width: auto;
}

/* Header */
.nav-container {
  /*background: black;*/
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  justify-content: flex-end;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23) !important;
  z-index: 10;
  /*padding: 5px 10px;*/
}

.nav-container.compact {
  position: absolute;
  right: 0;
  width: auto;
  box-shadow: none !important;
}

.app-page .nav-container {
  position: absolute;
  right: 0;
  width: 70%;
}
.ui.floating.dropdown.labeled.icon.button {
  color: white;
  background: #272b34;
}
.nav-logo {
  height: 35px;
  position: absolute;
  left: 5px;
  top: 5px;
}

.nav-container .ui.top.right.pointing.dropdown.button {
  /*position: absolute;
    right: 25px;
    top: 5px;
    padding: 0;*/
  outline: none;
  z-index: 5001;
}
.nav-container .ui.top.right.pointing.dropdown.button:hover,
.nav-container .ui.button:hover {
  background: none;
}

.nav-container .ui.button {
  background: none;
}
.nav-container .headshot {
  height: 35px;
  width: auto;
  border-radius: 12px;
}
.nav-container .headshot:hover {
  opacity: 0.8;
}

/*** Animations ***/

.mediate-fade-appear {
  opacity: 0;
}

.mediate-fade-appear.mediate-fade-appear-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.mediate-fade-enter {
  opacity: 0;
}

.mediate-fade-enter.mediate-fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.mediate-fade-leave {
  opacity: 0;
}

.mediate-fade-leave.mediate-fade-leave-active {
  opacity: 0;
  transition: opacity 300ms ease-in;
}

/*.nav-container .ui.simple.dropdown.item{
    color: white;
    float: right;
}
.nav-container .ui.inline.dropdown {
    color: white;
    position: absolute;
    right: 5px;
    top: 2px;
    background: #21252b;
    border-radius: 10px;
}
.nav-container .ui.avatar.image {
    border-radius: 0;
}
.nav-container img.ui.avatar.image {
    height: 26px;
    margin-right: 0px;
    border-radius: 10px 0 0 10px;
    border: 1px solid rgba(255, 255, 255, 0.2);
}
.nav-container .ui.inline.dropdown .menu {
    left: -87px;
    border-radius: 0 0 0 5px;
    background: black;
}
.nav-container .ui.dropdown .menu > .item {
    color: white;
}
.nav-container .ui.dropdown .menu > .item:hover{
    background: #21252b;
}*/

/*** Widgets ***/
.mediate-ui-widget-header {
  font-weight: 300;
  color: #000;
  width: 100%;
  background: -webkit-linear-gradient(top, #ccc 0%, #aaa 100%);
  background: -moz-linear-gradient(top, #ccc 0%, #aaa 100%);
  background: -o-linear-gradient(top, #ccc 0%, #aaa 100%);
  box-shadow: 0px 2px 0px #575757 inset;
  padding: 5px;
  border-radius: 2px;
}

.mediate-ui-widget {
  background: -webkit-linear-gradient(top, #303030 0%, #292929 100%);
  background: -moz-linear-gradient(top, #303030 0%, #292929 100%);
  background: -o-linear-gradient(top, #303030 0%, #292929 100%);
  border-radius: 2px;
  padding: 10px;
  margin: 10px;
}

.mediate-ui-widget-label {
  font-weight: 300;
  color: #ccc;
}

.mediate-marker-label {
  padding: 5px;
  color: #fff;
}

.admin-marker-label-portal,
.mediate-note-portal {
  position: absolute !important;
  right: 0px !important;
  bottom: 0px !important;
  margin: 35px !important;
  background: #2e3542 !important;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23) !important;
  z-index: 10;
}

.admin-marker-label-portal > * .header,
.admin-marker-label-portal > * .admin-marker-label-description,
.mediate-note-portal > * .header,
.mediate-note-portal-description {
  color: #fff !important;
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.admin-marker-label-extra-content,
.mediate-note-portal-extra-content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
  flex-wrap: wrap;
}

.mediate-current-schema-label {
  align-self: center;
  color: #fff;
  font-size: 24px;
  font-weight: 700;
}

.mediate-sidebar-dimmer.show {
  position: absolute;
  display: block;
  background: rgba(0, 0, 0);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 500ms ease-in-out;
}

.mediate-sidebar-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.mediate-sidebar-dimmer.hide {
  opacity: 0;
}

.mediate-sidebar.hide {
  height: 0;
}
.mediate-sidebar.show {
  height: 80vh;
}
.mediate-sidebar {
  background: #131820;
  position: absolute;
  z-index: 11;
  bottom: 0;
  width: 100vw;
  transition: height 500ms ease-in-out;
  box-shadow: 0 0px 12px rgba(0, 0, 0, 0.36), 0 0px 12px rgba(0, 0, 0, 0.43) !important;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}
