.mediate-schema-admin-container {
  height: 95% !important;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.mediate-schema-container {
  position: relative;
  display: flex;
  margin-top: 0px;
  height: 100%;
}

.mediate-form-add-schema-active-tab {
  height: 100% !important;
}

.mediate-schema-create-group {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  padding: 15px;
  background: #131720 !important;
}

.mediate-schema-options-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 0;
  flex: 0.3;
}

.mediate-schema-options-container.read-only {
  flex: 0.1;
  justify-content: space-between;
}

.mediate-schema-read-only-title {
  color: #fff;
  margin-bottom: 0px;
}

.mediate-schema-options {
  display: flex;
  align-items: center;
}

.mediate-schema-group-options {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mediate-schema-extra-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 20px;
}

.mediate-schema-info-trigger {
  color: #fff;
  z-index: 1001;
  cursor: pointer;
  margin-right: 15px !important;
}

.mediate-schema-info-container {
  position: relative;
  background: #fff;
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.mediate-schema-info-container .mediate-tab-form-toggle-public {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.mediate-schema-info-container > * .mediate-tab-form-toggle-public-text {
  margin: 10px 0px !important;
}

.mediate-schema-created-info {
  margin: 5px 0px !important;
}

.mediate-schema-text-field {
  color: #fff !important;
  margin-bottom: 10px;
  padding: 5px;
  border-radius: 5px;
}

.mediate-schema-text-field.read-only {
  color: #000 !important;
}

.mediate-schema-text-field textarea {
  width: 100%;
  resize: none;
  border: none;
  font-family: "Lato", "Helvetica", "Arial";
}

#mediate-schema-name-field {
  font-size: 24px;
  font-weight: bold;
}

#mediate-schema-description-field {
  font-size: 14px;
  color: #666;
}

.mediate-schema-text-field textarea:focus {
  outline-style: none;
}

.schema-group-container {
  width: 100%;
  min-height: 0;
  margin-top: 15px;
  max-height: 500px;
  min-height: 0;
  flex: 2;
}

.schema-group-container > * .simplebar-offset {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 0;
}

.schema-group-container.compact {
  min-height: 0;
  max-height: 145px;
  flex: 1;
}

.mediate-marker-search-container {
  max-height: 0px;
  opacity: 0;
  overflow: hidden;
  transition: 500ms ease-in-out;
  transition-property: max-height, opacity;
  flex: 2.5;
}

.mediate-marker-search-container.with-search {
  max-height: 500px;
  min-height: 0;
  opacity: 1;
  overflow: hidden;
}

.mediate-marker-search-container .mediate-admin-search-form {
  padding: 0px !important;
}

.schema-group-container > * .simplebar-content-wrapper {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.schema-group-container > * .simplebar-content {
  display: flex;
  flex-direction: row;
  min-height: 0;
  height: 100%;
  width: 100%;
  -webkit-user-select: none; /* Safari */
  user-select: none;
  position: relative;
}

.schema-group-container .simplebar-horizontal .simplebar-scrollbar::before {
  background: #fff;
}

.schema-group {
  padding: 15px;
  background: #2e3542;
  border-radius: 10px;
  display: flex !important;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 10px;
  height: 98%;
}

.schema-group.read-only {
  color: #fff;
}

.schema-group > * .mediate-admin-marker-label-read-only {
  background: #131720;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 10px;
  color: #fff;
}

.mediate-admin-marker-label-read-only-actions {
  display: flex;
  align-items: baseline;
}

.mediate-admin-marker-label-read-only-name {
  display: flex;
  justify-content: space-between;
}

.schema-group > * .simplebar-content-wrapper {
  width: 100% !important;
}

.schema-group.read-only > * .schema-group-name-field {
  opacity: 1 !important;
}

.schema-group-name-field {
  padding: 5px;
  border-radius: 5px;
}

.schema-group-name-field.error > * .prompt.label {
  position: absolute !important;
  left: 0;
  right: 0px;
}

.schema-group-read-only-color-display {
  width: 15px;
  height: 15px;
  border-radius: 10px;
  margin-right: 10px;
}

.schema-group-marker-list {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.schema-group-marker-list > * .simplebar-content {
  flex-direction: column !important;
}

.schema-group-settings {
  display: flex;
  justify-content: baseline;
  align-items: center;
}

.schema-group-name-field input {
  color: #fff !important;
  font-size: 18px;
}

.schema-group-add-marker-button {
  color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: red;
  cursor: pointer;
  padding: 5px 0px;
  border-radius: 0px 0px 10px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.schema-group-add-marker-button-text {
  background: #2e3442;
  display: flex;
  justify-content: center;
  align-items: baseline;
  padding: 4px;
  border-radius: 5px;
}

.schema-group-delete-button,
.schema-group .confirm-delete-container {
  color: #fff;
  align-self: flex-end;
  cursor: pointer;
}

.schema-group-activity {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  border-top: 1px solid #131720;
  width: 100%;
  padding-top: 5px;
}

.schema-group-markers-count {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

/*** Marker Labels ***/
.mediate-admin-marker-label {
  background: #0e1013;
  padding: 10px;
  border-radius: 5px 10px 10px 5px;
  position: relative;
  display: block;
  margin-bottom: 10px;
  overflow: auto;
  cursor: pointer;
}

.mediate-admin-marker-label-draggable {
  width: auto !important;
}

.admin-marker-label-color {
  position: absolute;
  width: 5px;
  height: 100%;
  left: 0;
  top: 0;
}

.admin-marker-label-field > * input {
  color: #fff !important;
}

.admin-marker-label-container {
  display: flex;
  justify-content: baseline;
  align-items: center;
}

.admin-marker-label-toggle-desc {
  color: #fff;
}

#admin-marker-label-description-field {
  border-top: 1px solid #666 !important;
  padding-top: 5px !important;
}

.admin-marker-label-delete {
  color: #fff;
  position: absolute;
  right: 0;
  top: 0;
  margin: 5px 5px 0px 0px !important;
  cursor: pointer;
}

.mediate-admin-marker-label-read-only {
  border-bottom: 1px solid #2e3442;
  font-size: 14px;
  padding-bottom: 5px;
  cursor: pointer;
  opacity: 1;
}
/** should move to common **/


.mediate-admin-marker-label-read-only:hover {
  opacity: 0.8;
}

.mediate-admin-marker-label-read-only-owner {
  color: #777;
}

.admin-marker-label-popup {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mediate-marker-admin-container {
  height: 100%;
}

.mediate-marker-admin {
  width: 100%;
  height: 90%;
  background: #131720;
}

.mediate-marker-actions {
  padding: 20px;
}

.mediate-basic-marker-list,
.mediate-basic-marker-list > * .simplebar-content {
  width: 100%;
  height: 100%;
}

.mediate-marker-labels-display-container {
  padding: 10px;
  width: 100%;
  height: 100%;
}

.mediate-basic-admin-marker-label {
  padding: 5px;
  background: #000;
  color: #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.mediate-admin-marker-label-title {
  margin: 0px;
}

/*** color picker ***/
.mediate-color-picker-popup-trigger {
  width: 15px;
  height: 15px;
  border-radius: 8px;
  margin-right: 5px;
  cursor: pointer;
}

/*** Search Results ***/
.mediate-schema-result-header {
  font-size: 28px !important;
}

.mediate-search-result-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mediate-schema-result-created-container {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
}

.mediate-schema-result-info-container {
  position: relative;
  display: block;
  flex: 0.7;
  margin-top: 5px !important;
}

.mediate-search-result-description {
  text-overflow: ellipsis;
  overflow-y: auto;
  overflow-x: hidden;
  flex: 0.3;
  margin-bottom: 5px;
}

/*** Stats ***/
.mediate-schema-usage-stats {
  margin-top: 10px !important;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.mediate-schema-usage-stats .mediate-admin-subheader {
  color: #000;
}

.mediate-schema-usage-stat {
  display: flex !important;
  flex-direction: row;
  align-items: center !important;
  justify-content: center !important;
  width: 100px;
  margin: 0px 5px !important;
}

.mediate-schema-usage-stat .attached.label {
  display: flex;
  justify-content: center;
}

.schema-cloned-hierarchy-node {
  margin: 10px !important;
  padding: 0px 5px !important;
  border-left: 1px solid #ccc;
}

.schema-cloned-hierarchy-image-container {
  display: flex;
  align-items: center;
}

/*** Basic Schema Display ***/
.mediate-basic-schema-display-container {
  overflow: hidden;
}
.mediate-basic-schema-display-actions {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}

.mediate-schema-result-detail-card-content {
  padding: 25px !important;
}

.mediate-basic-schema-display-actions > button {
  margin-bottom: 5px !important;
}

/*** Errors ***/
.mediate-schema-error-container {
  position: absolute !important;
  bottom: 0;
  right: 0;
}
.mediate-schema-error-prompt {
  color: #fff !important;
}
