.mediate-admin-container {
  background: #fff;
  height: 100%;
  overflow-y: hidden;
  position: relative;
}

.mediate-admin-root {
  height: 95vh;
  width: 100%;
  overflow-y: hidden;
  padding-bottom: 20px;
}

/*** PROJECT OVERVIEW ***/
.mediate-project-overview-container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
}

.mediate-project-overview-header-container,
.mediate-basic-asset-display-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  border-bottom: 2px solid #e4e5e8;
  padding: 10px;
  flex-wrap: wrap;
}

.mediate-basic-asset-filter {
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: stretch;
}

.mediate-basic-asset-filter-icon {
  color: #ccc !important;
}

.mediate-basic-asset-filter .input {
  flex: 1;
  margin-left: 10px;
}

.mediate-project-overview-header {
  margin-bottom: 0px !important;
  color: #000;
}

.mediate-project-overview-column,
.mediate-admin-all-data-container {
  height: 100%;
}

.mediate-admin-all-data-row {
  flex: 0.5;
  overflow: hidden;
}

.mediate-project-overview-row {
  height: 100%;
}

.mediate-admin-notification {
  overflow: hidden !important;
  height: 100%;
}

.mediate-projects {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 10px;
  overflow: auto;
}

.mediate-project-display {
  margin-top: 20px;
  flex: 1;
  background: #f0f0f0 !important;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.mediate-project-title {
  color: #000 !important;
  width: 100%;
  background: #e4e5e8;
  padding: 20px;
  border-radius: 5px 5px 0px 0px;
  margin-bottom: 0px;
  opacity: 1;
  transition: opacity 0.25s ease-in-out;
}

.mediate-project-title:hover {
  opacity: 0.7;
}

.mediate-project-description {
  color: #8c909e;
  padding: 0px 0px 0px 15px;
}

.mediate-project-options {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
}

.mediate-project-option-button {
  cursor: pointer;
  margin: 5px !important;
}

/*** Labels ***/
.mediate-admin-label-inverted {
  background: #828489 !important;
  color: #fff !important;
}

/*** Collaborators ***/
.mediate-project-social-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
}
.mediate-project-private-notification {
  min-height: 0;
  flex: 0.25 !important;
}

.mediate-project-private-notification-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-x: hidden;
  position: relative;
  height: 100%;
}

.mediate-project-private-notification-status,
.mediate-project-private-notification-message {
  margin: 10px 0px !important;
  color: #000;
}

.mediate-collaborator-display-container {
  min-height: 0;
  flex: 0.6 !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
}
.mediate-project-collaborators-container {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  width: 100%;
  padding: 10px 0px 10px 0px;
  margin-bottom: 10px;
}
.mediate-project-collaborators {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 0px 0px 0px 5px;
}

.mediate-project-collab-list {
  height: 100%;
}

.mediate-project-collab-options {
  overflow: hidden;
}

.mediate-collaborator-display-text {
  color: #8c909e;
  margin-top: 0px;
  padding: 0px 0px 0px 5px;
}

.mediate-collaborator-icon {
  width: auto;
  display: flex;
  flex-wrap: wrap;
}

.mediate-collaborator-image-container {
  display: flex;
  align-items: center;
  min-width: 0;
}

.mediate-collaborator-image-title-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.mediate-collaborator-image-title {
  margin-left: 5px;
  font-size: 16px;
  font-weight: bold;
  color: #000;
}

.mediate-collaborator-image-subtitle {
  margin: 0px 0px 0px 5px !important;
  font-size: 12px;
}

/*** Individual Project View ***/
.mediate-project-title-large {
  font-size: 28px;
  margin: 5px !important;
}

.mediate-project-view-root {
  width: 100vw;
  height: 100vh;
}

.mediate-project-view-grid {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  height: 100%;
}

.mediate-project-display-right,
.mediate-project-display-left {
  margin: 20px;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.mediate-project-display-left {
  flex: 0.6 !important;
}

.mediate-project-display-right {
  flex: 0.4 !important;
}

.mediate-project-tab {
  padding: 30px;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #000;
}

.mediate-form-tabs-container {
  height: 90% !important;
  overflow-y: hidden;
}

.mediate-form-tabs-header {
  color: #fff;
  background: #2e3442;
  margin-bottom: 0px !important;
  padding: 20px;
  border-radius: 5px 5px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.mediate-form-tabs-header-placeholder {
  width: 120px;
}

.mediate-form-tabs-header-title {
  margin: 0 !important;
}

.mediate-admin-subheader {
  margin: 5px 0px 0px 0px !important;
}

.mediate-form-tabs-item {
  padding: 10px !important;
  margin: 0px !important;
}

.mediate-form-tabs-item-button {
  background: #131720 !important;
  color: #fff !important;
}

.mediate-form-tabs-active-tab {
  position: relative;
  width: 100%;
  height: 100%;
}

.mediate-form-tabs-active-tab > * .mediate-project-overview-header,
.mediate-form-tabs-active-tab > * .mediate-search-result-info,
.mediate-form-tabs-active-tab > * .mediate-search-media-result-info,
.mediate-form-tabs-active-tab > * .mediate-collaborator-image-title {
  color: #fff !important;
}

.mediate-form-tabs-active-tab .active.tab {
  padding: 0px !important;
  border-radius: 0px 0px 5px 5px !important;
  height: 100%;
}

.mediate-form-tabs-menu-container > * .active.tab {
  background: none !important;
  border: none !important;
}

.mediate-project-admin-link-container {
  display: flex;
  align-items: center;
}

.mediate-project-admin-link {
  font-size: 20px;
  color: #000;
  opacity: 1;
  cursor: pointer;
  transition: opacity 250ms ease-in-out;
}

.mediate-project-admin-link:hover {
  opacity: 0.6;
  color: #000;
}
.mediate-project-meta {
  min-height: 0;
  flex: 0.2;
}

.mediate-project-asset-container {
  min-height: 0;
  flex: 0.4;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: hidden;
  padding-bottom: 20px;
}

.mediate-project-asset-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
  background: #e4e5e8;
  border-radius: 5px 5px 0px 0px;
  margin-bottom: 0px;
  width: 100%;
}

.mediate-project-asset-title {
  margin-bottom: 0px;
  font-size: 20px;
  color: #000;
}

.mediate-project-asset-stats {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.mediate-project-asset-created {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 10px 0px 0px 0px !important;
  font-size: 12px;
}

.mediate-project-asset-meta {
  padding: 10px;
}

.mediate-project-asset-description {
  color: #000;
}

.mediate-project-assets-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.mediate-project-asset-list {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  background: #f0f0f0 !important;
  border-radius: 0px 0px 5px 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.mediate-basic-media-display-container,
.mediate-basic-display-container {
  overflow: hidden;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.mediate-basic-list {
  position: relative;
  height: 100% !important;
  padding: 15px;
  overflow-x: hidden;
}

.mediate-basic-list > * .simplebar-content {
  height: 100%;
  position: relative;
}

.mediate-basic-display-container {
  height: 100%;
}

.mediate-basic-list.inverted {
  background: #1c242f;
  border-radius: 5px;
}

.mediate-basic-list > * .mediate-media-search-result,
.mediate-search-result {
  padding: 10px;
  cursor: pointer;
  opacity: 1;
  transition: opacity 250ms ease-in-out;
  display: flex;
}

.mediate-search-media-result-info,
.mediate-search-result-info {
  display: flex;
  flex-direction: column;
  margin-left: 2px;
  color: #000;
}

.mediate-search-media-result-owner,
.mediate-search-result-owner {
  font-size: 12px;
  opacity: 0.8;
}

.mediate-search-media-result-label {
  width: 25px !important;
}

.mediate-project-film-title {
  margin: 0px !important;
}

.mediate-project-film-marker-count {
  margin-right: 10px !important;
}

.mediate-project-film-display,
.mediate-project-asset-display {
  background: #fff;
  margin: 10px 10px 20px 10px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16), 0 1px 3px rgba(0, 0, 0, 0.23) !important;
}

.mediate-project-asset-display.inverted {
  background: #13171f;
  color: #fff;
  padding: 10px;
}

.mediate-project-asset-display.inverted > * .mediate-project-asset-title {
  color: #fff;
}

.mediate-project-options-buttons {
  position: relative;
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0px 5px 5px 5px;
  border-top: 1px solid #e4e4e8;
}

.mediate-project-asset-display.inverted > .mediate-project-options-buttons {
  border-top: 1px solid #22262c;
}

.mediate-project-writable-options-container {
  display: flex;
  align-items: baseline;
  margin-top: 10px;
}

.mediate-project-asset-display-text-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background: #e4e4e8;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.mediate-project-asset-display.inverted
  .mediate-project-asset-display-text-container {
  background: #13171f;
}

.mediate-project-film-meta {
  display: flex;
}

/*** Modals ***/
.mediate-project-sidebar-overlay {
  display: flex;
  flex-direction: column;
  background: #21252c !important;
  height: 50%;
}

.mediate-project-tabs-back-button {
  align-self: flex-end;
  cursor: pointer;
}

.mediate-confirm-delete .content {
  color: #000;
}

/*** Modal Forms ***/
.mediate-tab-form {
  margin-bottom: 20px !important;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 400px !important;
  background: #13171f !important;
  box-shadow: 0 1px 3px rgba(34, 31, 31, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24) !important;
}

.mediate-tab-form-toggle-public {
  display: flex;
  align-items: baseline;
  margin: 0px 0px 20px 0px;
}

.mediate-tab-form-toggle-public-text {
  margin-left: 20px;
}

.mediate-form-label {
  border-bottom: 1px solid #444;
  padding-bottom: 5px;
  margin-bottom: 15px !important;
}

.mediate-form-upload-info {
  margin-left: 10px;
}

.mediate-form-header {
  padding-bottom: 5px;
  margin-bottom: 15px !important;
}

.mediate-form-add-button {
  align-self: center;
  margin-top: 20px !important;
}

.mediate-form-accordion-container {
  margin-top: 30px !important;
  background: #fff;
  padding: 20px;
  border-radius: 5px;
}

.mediate-form-accordion-title {
  font-size: 20px !important;
  border-bottom: 1px solid #444;
}

.mediate-add-project-prompt,
.mediate-add-project-form-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mediate-add-project-prompt .mediate-project-title {
  background: none;
  text-align: center;
  font-size: 32px;
}

.admin-root-add-project-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.mediate-add-project-form-header {
  width: 80%;
  color: #fff;
  background: #2e3442;
  margin-bottom: 0px !important;
  padding: 20px;
  border-radius: 5px 5px 0px 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.mediate-add-project-form-title {
  margin: auto !important;
}

.mediate-add-project-form {
  width: 80%;
  background: #13171f !important;
  padding: 20px;
  border-radius: 0px 0px 5px 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.mediate-form-tabs-menu-container {
  position: relative;
  display: block;
  height: 80%;
}

.mediate-form-tabs-menu {
  background: rgb(5, 5, 5) !important;
  padding: 20px;
  border-radius: 0px 0px 5px 5px;
  position: absolute !important;
  top: 0;
}

.mediate-form-tabs-active-tab-dimmer {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 500ms ease-in-out;
}

.mediate-form-tabs-active-tab-dimmer.visible {
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 3;
}

.mediate-form-add-media-pane {
  background: none !important;
  border: none !important;
  padding: 0px !important;
  border-radius: 0px 0px 5px 5px !important;
}

.mediate-admin-link {
  color: #6a6c76;
}

.mediate-admin-link:hover {
  color: #b1b1b1;
}

.mediate-admin-project-user-asset-display {
  background: #13171f !important;
  height: 100%;
  margin: 0 !important;
}

.file-display {
  cursor: pointer;
  margin: 2px !important;
}

.file-display:hover {
  opacity: 0.8;
}

.mediate-form-upload-info {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
}

.mediate-form-file-field-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

/*** ADMIN NOTIFICATIONS ***/
.admin-notification-children {
  width: 100%;
  height: 100%;
  position: relative;
  display: block;
}

.mediate-admin-notification-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  border-bottom: 1px solid #ccc;
}

.mediate-admin-notification-sidebar {
  min-height: 15vh;
  width: 200px !important;
  color: #21252c !important;
}

.mediate-admin-notificaton-close-button {
  cursor: pointer;
  align-self: flex-start;
}

.actions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: baseline;
}

.mediate-admin-actions-button:first-child {
  margin-left: 0px !important;
}

/*** Collaborators ***/

/*** Schema ***/
.mediate-schema-container {
  margin-top: 20px;
  height: 100%;
}

.mediate-marker-type-count-label {
  width: 25px;
  height: 25px;
  margin-right: 5px;
  background-color: #28adff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  color: #fff;
}

/*** Form validation errors ***/
@keyframes error {
  0% {
    border: 1px solid rgba(0, 0, 0, 0);
  }
  50% {
    border: 1px solid rgba(255, 0, 0, 1);
  }
  100% {
    border: 1px solid rgba(0, 0, 0, 0);
  }
}
.has-error {
  animation-name: error;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

/*** Upload Info ***/
.media-upload-info-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 5px 0px;
  border-radius: 5px;
  width: auto;
}

.media-upload-info-trigger {
  cursor: pointer;
}

.media-upload-info-detail-card {
  position: absolute !important;
  right: 0px !important;
  bottom: 0px !important;
  margin: 35px !important;
  height: 200px;
  box-shadow: 0 6px 9px rgba(0, 0, 0, 0.36), 0 6px 9px rgba(0, 0, 0, 0.43) !important;
  z-index: 10;
  overflow: hidden;
}

.media-upload-info-detail-card .content {
  overflow: hidden;
  height: 100%;
}

.media-upload-info-detail-card .content .description {
  height: 90%;
  overflow: hidden;
}

.media-upload-info-detail-header {
  padding-bottom: 5px;
  margin-bottom: 3px;
  border-bottom: 1px solid #ccc;
}

.media-upload-info-detail-list {
  position: relative;
  height: 95% !important;
  overflow-x: hidden;
  padding: 15px;
}

.media-upload-info-detail-list > * .simplebar-content {
  height: 100%;
  position: relative;
}
