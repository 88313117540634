@import "https://fonts.googleapis.com/css?family=Lato:100,300,400,900";

.note-thread-content {
  font-family: "Lato", sans-serif;
  color: #fff !important;
  background: #22262c !important;
  border-radius: 0 !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center;
  margin: 10%;
}

.mediate-note-modal {
  border-radius: 0 !important;
  z-index: 5001 !important;
  background-color: #22262c !important;
}

.note-thread-title {
  font-family: "Lato", sans-serif;
  color: #fff !important;
  font-weight: 100 !important;
  background: #22262c !important;
  border-bottom: 1px solid #ccc !important;
  border-radius: 0 !important;
  display: flex !important;
  align-items: center;
  padding: 25px 10px 25px 10px;
}

.note-title-text {
  font-size: 24px;
}

.note-thumbnail {
  width: 25%;
  height: auto;
  flex: 0.3;
}

.note-input-form {
  display: flex !important;
  align-items: center;
  flex-direction: column !important;
}

.note-submit {
  align-self: flex-end;
  margin-top: 20px !important;
}

.note-input {
  width: 90%;
  padding-top: 25px;
}

/** New basic note thread **/

.mediate-basic-note-thread-container {
  display: flex;
  flex-direction: row;
  height: 70vh;
  overflow-y: hidden;
  padding: 0 !important;
  position: relative;
}

.mediate-basic-note-thread-container .no-comments {
  align-items: stretch;
  justify-content: stretch;
}

.mediate-basic-comment-thread-group {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  overflow: auto;
  max-width: 100% !important;
  padding: 10px;
  background: #0e1218;
  flex: 1;
  margin-top: 10px !important;
}

.mediate-basic-comment-thread-comment-content {
  display: flex !important;
  flex-direction: column;
  max-width: 100% !important;
}

.mediate-basic-note-thread-header {
  background: #2e3138 !important;
  color: #fff !important;
  padding: 20px !important;
  font-size: 24px;
  font-weight: 600;
}

.mediate-basic-note-thread-content {
  padding: 20px;
  height: 100%;
}

.mediate-basic-note-thread-comments-header {
  color: #fff !important;
}

.mediate-basic-comment-thread-comment-author,
.mediate-basic-comment-thread-comment-text {
  color: #fff !important;
  max-width: 650px;
}

.mediate-basic-comment-thread-comment-date,
.mediate-basic-comment-form-status-label > label,
.mediate-basic-comment-thread-comment-actions > a,
.mediate-basic-comment-thread-comment-actions > .confirm-delete-container > a {
  color: #ccc !important;
}

.mediate-basic-comment-thread-comment-date {
  margin-left: 0px !important;
}

.mediate-basic-comment-thread-comment .comments {
  box-shadow: -1px 0 0 rgb(46 49 56) !important;
  margin-bottom: 10px !important;
}

.mediate-comments-thread-circular-avatar {
  display: block;
  width: 2.5em;
  height: 100%;
  float: left;
  margin: 0.2em 0 0;
}

.mediate-comments-thread-circular-avatar ~ .content {
  margin-left: 3.5em;
}

.mediate-basic-comment-thread-comment-status {
  padding-left: 10px;
}

.mediate-basic-note-thread-form {
  flex: 1;
  margin-right: 20px;
}

.mediate-basic-note-thread-form > .mediate-basic-comment-thread-textarea {
  max-width: 100%;
}

.mediate-basic-note-thread-form .button {
  margin-top: 10px !important;
}

.mediate-basic-comment-thread-textarea {
  margin: 10px 0px !important;
  max-width: 750px;
}

.mediate-basic-comment-thread-textarea textarea {
  color: #fff !important;
  padding: 5px;
  border-radius: 2px;
  background: #2e3138 !important;
  width: 100%;
  resize: none !important;
  border: none;
  font-family: "Lato", "Helvetica", "Arial";
  outline: none !important;
  margin: 0.25em 0 0.5em;
  font-size: 1em;
  word-wrap: break-word;
  color: rgba(0, 0, 0, 0.87);
  line-height: 1.3;
}

.mediate-basic-comment-thread-textarea:focus {
  border-color: none !important;
}

.mediate-basic-comment-thread-replies {
  display: block;
}

.mediate-basic-comment-thread-replies.hide {
  flex: 0;
}

.mediate-basic-comment-thread-replies-toggle {
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px 0px;
}

.mediate-basic-comment-thread-replies {
  max-width: 100% !important;
}

.mediate-basic-comment-thread-add-new-container {
  position: sticky;
  top: 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  z-index: 2;
}
