.hp-logo {
    position: fixed;
    top: 30px;
    left: 0;
    right: 0;
    margin: auto;
    width: 250px;
}

.hp-login-container{
    width: 350px;
    position: absolute;
    margin: auto;
    left: 0;
    right:0;
    margin-top: 20px;
}

.hp-content {
    text-align: center;
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.hp-body-container,
.hp-body{
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.content__heading {
    margin-bottom: 24px;
    color: #272727;
    font-size: 44px;
}


.hp-body-container {
    background: linear-gradient(-90deg, #feea00, #f94a6f, #9fe966, #28adff);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    width: 100%;
    height: 100%;
}

.hp-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: rgba(19, 23, 32, 0.5);
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}



.hp-field-margin-0 {
    margin-bottom: 0!important;
}

.hp-forgot-pass-container {
    width: 350px;
    color: white;
    padding: 30px;
}
.hp-forgot-pass-container a{
    background: rgba(0, 0, 0, 0.5);
    padding: 5px 15px;
    margin: 0 5px;
    border-radius: 2px;
    color:#29acff;
}

.hp-login-container form .ui input[name=email],
.hp-login-container form .ui input[name=email]:focus{
    border-radius: 5px 5px 0 0;
}
.hp-login-container form .ui input[name=password],
.hp-login-container form .ui input[name=password]:focus{
    border-radius: 0 0 5px 5px;
}

.mediate-intro-video {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 1;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

/*** NEW SEMANTIC UI LOGIN FORM ***/
.mediate-login-form-field {
  margin-bottom: 0 !important;
}

.mediate-login-submit {
  margin-top: 20px !important;
}

.mediate-login-error.show {
  display: block;
}

.mediate-login-error.hide {
  display: none;
}

.toggle-icon-label {
  cursor: pointer;
}

.mediate-sign-up-header, .hp-tagline {
    padding-bottom: 20px;
    color: #fff;
}

.mediate-sign-up-container, .mediate-verify-account-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0% 10%;
    align-items: center;
    flex-grow: unset;
}

.mediate-sign-up-form {
    width: 400px;
}

.mediate-sign-up-confirmation {
    display: flex;
    flex-direction: column;
}

.mediate-verify-account-message {
    width: 350px;
}

/* MEDIA QUERIES */
@media only screen and (max-width: 700px) {

}
