.get-data-container {
  position: relative;
  margin-left: 10px;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100vh;
}

.selected-film-list {
  position: relative;
  background-color: #3e4452;
  border-radius: 5px;
}

.selected-film {
  list-style: none;
}

.selected-film-title {
  display: inline-block;
}

.selected-film .set-query-to-player-button {
  position: relative;
  display: inline-block;
  left: 15px;
  cursor: pointer;
}

.selected-film h1,
.get-data-select-groups h1,
.get-data-page-header {
  font-weight: 300 !important;
  font-size: 35px;
  color: #fff;
}

.selected-film .ui.checkbox {
  padding: 5px;
}

.file-select-buttons .ui.checkbox label {
  color: #fff;
}

.file-select-buttons .ui.radio.checkbox {
  padding: 10px;
}

.file-select-buttons span {
  color: #ccc;
}

.selected-film .ui.checkbox label {
  color: #ccc;
}

.get-data-container h4 {
  font-weight: 300 !important;
  color: #fff;
}

.research-group-marker-type-filter {
  padding: 5px;
}

.get-data-container .ui.multiple.dropdown {
  background: #ccc;
}

/*** Data Download Component ***/
.data-download-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 30px;
}

.data-download-link h4 {
  margin-top: 10px !important;
}

.data-download-link a i.icon {
  font-size: 40px;
}

.data-download-link a {
  color: #ccc;
}

.data-download-link a:hover {
  opacity: 0.6;
}

/*** Read Only Video Player ***/
.read-only-interface-container {
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 100%;
}

.read-only-interface-container .app-marker-list-container ul {
  padding: 0px 0 0 0;
  margin: 0 0 0 70px;
}

.read-only-video-container {
  position: relative;
}

.read-only-video-container .video-js.vjs-fluid {
  width: 80%;
  max-width: 80%;
  height: 0;
  left: 30%;
  top: -30px;
}

.close-modal {
  position: absolute;
  right: 0px;
  z-index: 3;
  top: -25px;
}

.marker-research-group {
  position: absolute;
  bottom: 0px;
  left: 0px;
  font-size: 10px;
}

.data-fetching-loader-message {
  color: #fff;
}

/** New data querying prototype **/
.mediate-project-data-query-schema-container {
  padding: 10px;
  margin-top: 20px;
  border: 1px solid #3e4452;
  border-radius: 5px;
}

.mediate-project-data-query-accordion {
  margin-top: 30px;
  flex: 0.7;
  margin-bottom: 0px !important;
  background: #13171f !important;
  overflow-y: hidden;
}

.mediate-project-data-query-container {
  display: flex;
  height: 100%;
  overflow-y: hidden;
}

.mediate-project-data-query-results-container {
  flex: 0.3;
  margin-top: 0px !important;
  background: #0e1218 !important;
}

.mediate-project-data-query-heading {
  padding-bottom: 10px !important;
  border-bottom: 1px solid #3e4452 !important;
}

.mediate-project-data-query-accordion-title {
  color: #fff !important;
}

.mediate-project-data-query-scroll-container {
  position: relative;
  height: 95%;
  padding-right: 30px;
}

.mediate-project-data-query-dropdown {
  background-color: #0e1218 !important;
  border: 1px solid #3e4452 !important;
}

.mediate-project-data-query-dropdown input {
  color: #fff !important;
}

.mediate-project-data-query-dropdown > * .message {
  color: #fff !important;
}

.mediate-project-data-query-dropdown .menu {
  background: #0e1218 !important;
  border-color: #3e4452 !important;
}

.mediate-project-data-query-dropdown > .dropdown.icon {
  color: #fff;
}

.mediate-project-data-query-dropdown .menu .item {
  color: #fff !important;
  border-top: 1px solid rgb(55, 55, 55) !important;
}

.mediate-project-data-query-label-heading {
  color: #fff !important;
}

.mediate-project-data-query-button-container {
  margin-top: 20px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mediate-project-data-query-loader {
  margin-left: 20px !important;
}

.mediate-project-data-query-overview {
  display: flex;
  justify-content: center;
}

.mediate-project-data-query-format-container {
  padding-top: 20px;
  display: flex;
  justify-content: center;
}

.mediate-project-data-query-format-container .checkbox:nth-child(1) {
  margin-right: 15px !important;
}

.mediate-project-data-query-format-container label {
  color: #fff !important;
}

.mediate-project-data-query-format-label {
  padding-top: 20px;
  display: flex;
  text-align: center;
  font-style: italic;
}
