.hp-body-container {
  overflow: hidden;
}
/*Heading*/
.page-header-container {
  padding: 20px 40px;
  width: 100%;
}
.page-header-container h1 {
  font-size: 35px;
  margin: 0px 0px 10px 0px;
  color: #fff;
  width: 100%;
}
.page-header-container h6 {
  font-size: 16px;
  margin: 0;
  color: #9da5b4;
  border-bottom: 1px solid #9da5b4;
  padding-bottom: 5px;
  margin: 5px;
  width: 100%;
}

.group-collaborators h4 {
  color: #9da5b4;
}

.mediate-dashboard-main-content {
  position: relative;
  height: 100%;
  scroll-behavior: smooth;
}

.mediate-dashboard-project-prompt {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  color: #fff;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.mediate-project-prompt-admin-link {
  color: #2185d0;
  margin: 0px 5px;
}

.mediate-research-group-container {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
}

.mediate-dashboard-scroll-container {
  position: relative;
  height: 95%;
}

.mediate-dashboard-main-content {
  flex: 1;
  min-width: 0;
}

.mediate-project-menu-toggle {
  color: #fff;
  position: absolute;
  right: 0;
  top: 0;
  margin: 20px !important;
  cursor: pointer;
  z-index: 1;
}

/** Sidebar **/
.mediate-projects-menu {
  background: #090d13;
  position: absolute !important;
  display: flex;
  flex-direction: column;
}

.mediate-projects-menu-list-item {
  margin: 15px;
  cursor: pointer;
}

.mediate-projects-menu-list-container {
  margin-top: 20px !important;
  border-top: 1px solid #282c34;
}

.mediate-filter-projects-container {
  color: #fff;
  display: flex;
  justify-content: stretch;
  align-items: center;
  margin: 20px 0px 10px 10px;
}

.mediate-filter-projects-container > * input {
  color: #fff !important;
}

/** sidebar color - #090d13 **/
.mediate-dashboard-container {
  height: 100%;
  background: #000;
}

/*Videos*/
.dash-video-list-container {
  padding: 10px 40px;
}
.dash-video {
  position: relative;
  width: 49%;
  float: left;
}
.dash-video:nth-child(odd) {
  margin-right: 15px;
}
.dash-video-image {
  width: 100%;
  border-radius: 10px;
}
.dash-video-title {
  position: absolute;
  bottom: 4px;
  left: 0;
  padding: 150px 20px 75px 20px;
  right: 0;
  border-radius: 10px;
  font-size: 27px;
  font-weight: 300;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+99&0+0,0.92+100 */
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.91) 99%,
    rgba(0, 0, 0, 0.92) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.91) 99%,
    rgba(0, 0, 0, 0.92) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.91) 99%,
    rgba(0, 0, 0, 0.92) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#eb000000',GradientType=0 ); /* IE6-9 */
}
.dash-video-collab-container {
  position: absolute;
  bottom: 14px;
  left: 20px;
  width: 100%;
  display: block;
}
.dash-video .ui.label {
  position: absolute;
  right: 20px;
  top: 15px;
  background: #282c34;
  color: white;
  padding: 6px;
  font-size: 20px;
  font-weight: 400;
}

.dash-video .captions-label {
  position: absolute;
  left: 20px;
  font-size: 20px;
  background: #282c34;
  padding: 6px;
  border-radius: 2px;
  text-align: center;
  top: 15px;
}
/***
  Hiding this until we can display it a bit better
  TODO find a cleaner way to display active users
***/
.dash-video-collab-container .ui.label.collaborator-icon {
  display: none;
}
.dash-video img.mediate-icon-small {
  height: 15px !important;
  margin-right: 2px;
  margin-bottom: 2px;
}
.dash-video-year {
  position: absolute;
  bottom: 55px;
  left: 20px;
  color: #9da5b4;
}

.mediate-link {
  opacity: 1;
  color: #fff;
}

.mediate-link:hover {
  opacity: 0.7;
  color: #fff;
}

/*** FOR VIDEOS WITH NO THUMBS I.E. AUDIO ONLY ***/

.dash-video.no-thumb .dash-video-title {
  position: relative;
}

.dash-video-icon {
  position: absolute;
  font-size: 150px !important;
  top: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
  margin: auto !important;
  filter: blur(3px);
}

/* MEDIA QUERIES */
@media only screen and (max-width: 900px) {
  .dash-video {
    width: 100%;
    margin-bottom: 20px;
  }
}
