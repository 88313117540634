/*** MODAL CONTAINER ***/
.mediate-marker-modal-select-container {
  margin-left: 5px;
}
.mediate-marker-modal {
  z-index: 5001 !important;
}

.mediate-marker-modal .content {
  width: 100%;
  height: 100%;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
}

.mediate-marker-modal .content .center {
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  background-color: #242422;
  max-height: 1024px;
}

/*** Marker Filter ***/

.mediate-marker-modal-select-filter-header {
  font-weight: 200;
}

.mediate-marker-modal-select-filter-container {
  align-self: flex-start;
  margin: 10px;
  background-color: #303030;
  padding: 10px;
  width: 100%;
  display: flex;
  align-items: center;
}

.mediate-marker-modal-select-filter-container .mediate-ui-widget-label {
  margin: 0px 10px;
}

.mediate-marker-modal-select-filter-input {
  flex: 0.4;
}


.mediate-marker-modal-select-filter-input input {
  background-color: #3f3f3f !important;
}

/*** Current Markers ***/

.mediate-marker-modal-select-current-markers-container {
  display: flex;
  flex-direction: column;
  flex: 0.6;
  min-height: 50px;
}

.mediate-marker-modal-select-current-markers {
  width: 100%;
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  min-height: 25px;
}

.mediate-marker-modal-select-current-markers-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3f3f3f;
}

.mediate-marker-modal-select-current-markers-container .mediate-ui-widget-label {
  flex: 0.2;
}

/*** MARKER SELECT ***/

.mediate-marker-modal-select-group {
  display: flex;
  flex-direction: row;
  align-self: center;
}

.mediate-marker-modal-select-markers {
  flex: 0.8;
  min-width: 250px !important;
  overflow-y: auto;
  overflow-x: hidden;
}

.mediate-marker-modal-select-close {
  position: absolute;
  right: 0;
  margin: 40px !important;
  top: 0px;
}

.mediate-marker-modal-select-marker {
  margin: 2px;
  display: flex;
  background-color: #3f3f3f;
}

.mediate-marker-modal-select-description {
  z-index: 5001 !important;
  background-color: #ccc;
}

/*** MARKER SHORTCUTS ***/

.mediate-marker-modal-shortcuts-container {
  display: flex;
  width: 100%;
  height: auto;
  flex: 0.5;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-color: #303030;
  border-radius: 4px;
  margin-right: 20px;
  padding: 10px 10px 20px 10px;
  overflow-y:auto;
}

.mediate-marker-modal-shortcut-slot {
  background-color: #3f3f3f;
  height: auto;
  border-radius: 3px;
  padding: 10px 0px 10px 0px;
  margin-top: 10px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  height: 60px;
  width: 150px;
}

.mediate-marker-modal-shortcut-slot-label {
  width: 100%;
  background: #2185d0;
  margin-bottom: 5px;
  align-self: flex-start;
}

.mediate-marker-shortcut-label {

}

.mediate-marker-shortcut-remove {
  margin-left: 10px !important;
  padding: 2px !important;
}
