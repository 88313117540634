html {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.app-marker-list-container {
  /*position: fixed;*/
  flex: 0.3;
  word-wrap: break-word;
  overflow-y: auto;
  /*width: 30%;*/
  background: #131820;
  position: relative;
  padding-left: 0;
  scroll-behavior: smooth;
  margin-top: 0;
  height: 100%;
}

/*** NEW CLASSES FOR CLASSLESS CONTAINING ELEMENTS ***/
.mediate-root-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.mediate-ui-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  background: #0e1218;
  height: 100%;
  overflow: hidden;
}

/** MEDIATE PAGE NAV CONTAINER **/
.mediate-root-container .nav-container {
  flex: 0.1;
  height: auto;
}

.mediate-main-container {
  display: flex;
  width: 100%;
  position: relative;
  flex: 1;
  justify-content: flex-start;
  align-items: stretch;
  height: 100%;
  overflow: hidden;
}

.mediate-ui {
  position: relative;
  width: 100%;
  height: 100%;
}

.mediate-marker-container {
  width: 100%;
  flex: 0.3;
  overflow: hidden;
  position: relative;
  min-height: 0;
  max-height: 100vh;
}

.mediate-video-container {
  display: flex;
  width: 100%;
  flex: 0.7;
  position: relative;
}

.video-player-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.app-video-container {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  height: 100%;
  width: 100%;
}

.mediate-basic-video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.mediate-video-player {
  flex: 0.7;
  position: relative !important;
  padding-top: 1% !important;
  min-width: 0;
  width: 100%;
}

/*** Modified Add Marker Menu ***/

.add-marker-container > * {
  margin: 0px 5px !important;
}

.mediate-filter-marker-dropdown {
  background: #45505f !important;
  position: absolute !important;
  top: 50px;
}
.mediate-filter-marker-dropdown.show {
  opacity: 1;
  max-height: 300px;
  min-width: 300px !important;
  max-width: 500px !important;
  display: inline-block !important;
  transition: all 0.5s ease-in-out !important;
}

.mediate-filter-marker-dropdown.hide {
  opacity: 0;
  max-height: 0px;
  max-width: 0px !important;
  display: inline-block !important;
  transition: all 0.5s ease-in-out !important;
  margin: 0 !important;
  padding: 0 !important;
}

/**** Filtering ****/
.mediate-filter-marker-dropdown.hide .icon {
  display: none;
}

.mediate-schema-filter-container {
  background: #000;
  position: absolute !important;
  top: 0px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23) !important;
}

.schema-group-filter-container {
  margin: 10px;
}

.schema-group-filter-color {
  width: 15px;
  height: 15px;
  margin-right: 5px;
  border-radius: 10px;
  cursor: pointer;
}

.schema-group-filter-header {
  color: #fff !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.schema-group-filter-toggle-icon {
  font-size: 18px !important;
  cursor: pointer;
  position: absolute;
  right: 0;
  margin-right: 20px !important;
}

.schema-group-filter-list {
  margin: 0px 0px 0px 6px;
  padding: 0px 10px;
  transition: max-height 500ms ease-in-out;
  overflow-x: hidden;
  background: #0e1218;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.schema-group-filter-list.show {
  max-height: 1000px;
}

.schema-group-filter-list.hide {
  max-height: 0px;
}

.schema-group-filter-item {
  color: #fff;
  margin: 10px 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
}

.mediate-ui-info.show {
  border-bottom: 1px solid #444;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.mediate-ui-info.hide {
  width: auto;
}

.mediate-ui-info-container {
  color: #fff;
  align-self: center;
  position: absolute !important;
  top: 0px;
  left: 0;
  background: rgba(15, 19, 24, 0.85);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 120px;
  transition: max-width 500ms ease-in-out;
  width: 100%;
  transition: opacity 500ms ease-in-out;
}

.mediate-ui-info-tabs {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.mediate-ui-info-pane {
  background: none !important;
  border: none !important;
  position: relative;
  height: 100% !important;
  overflow: hidden !important;
  padding: 5px 10px !important;
}

.mediate-ui-info-scroll {
  height: 100%;
}

.mediate-ui-info-container > * .menu {
  background: #0f1318 !important;
  margin-bottom: 0px !important;
}

.mediate-ui-info-container > * .active.item {
  background: #2e3542 !important;
}

.mediate-ui-info-container > * .item:hover {
  background: #2e3542 !important;
}

.mediate-ui-info-label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.mediate-ui-info-toggle-icon {
  cursor: pointer;
}

.mediate-ui-info-value {
  display: flex;
  flex-wrap: wrap;
  transition: max-height 500ms ease-in-out;
  overflow: hidden;
}

.mediate-ui-info-value.show {
  max-height: 200px;
}

.mediate-ui-info-value.hide {
  max-height: 0px;
}

.mediate-ui-info-current-marker-types-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin: 5px;
}

.mediate-ui-info-current-marker-types-list .mediate-schema-active-marker {
  font-size: 12px;
  padding: 3px;
  border-radius: 3px;
}

.mediate-ui-info-container.hide {
  opacity: 0;
}

.mediate-ui-info-container.show {
  opacity: 1;
}

/* DEFAULT SCROLLBAR*/

/* Let's get this party started */
.app-marker-list-container::-webkit-scrollbar {
  width: 12px;
}

/* Track */
.app-marker-list-container::-webkit-scrollbar-track {
  /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
  /*-webkit-border-radius: 10px;*/
  /*border-radius: 10px;*/
  background: black;
}

/* Handle */
.app-marker-list-container::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  /*background: rgba(96,96,96,0.8);*/
  background: rgba(115, 133, 159, 0.6);
  /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);*/
}
.app-marker-list-container::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(115, 133, 159, 0.3);
}

.app-marker-list-container .ui.dropdown .menu > .header {
  margin: 8px 0 0 0;
  padding: 5px;
  /* border: none; */
  /*background: #21252c;*/
  /*color: white;*/
}

.app-marker-list-container ul {
  /*padding: 45px 0 0 0;
    margin: 0 0 0 70px;*/
}
.app-marker-list-container ul .group {
  padding: 0;
  margin: 0;
}
.app-marker-list-container .marker {
  position: relative;
  min-height: 80px;
  list-style-type: none;
  margin: 0;
  padding: 0px;
  /*border-left-style: solid;
    border-left-width: 4px;*/
  border-top: 1px solid rgba(0, 0, 0, 0.4);
  cursor: pointer;
  display: flex;
}
.app-marker-list-container .marker.repeated {
  border-top: 0px;
}
/** TODO set class on previous sibling somehow to put color bars together and remove bottom border**/
.app-marker-list-container .marker.repeated .marker-color-bar {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.app-marker-list-container .marker.sibling-repeated .marker-color-bar {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.app-marker-list-container .marker:focus {
  background: rgba(0, 0, 0, 0.2);
  outline: 3px solid #45505f;
}
.app-marker-list-container .marker-filter-container .ui.dropdown .menu {
  /*background: #45505f;*/
  z-index: 50000000;
  border-radius: 0 0 5px 5px !important;
  /*border: 1px solid white;*/
  margin-top: 0 !important;
  width: 80%;
  cursor: pointer;
}

.app-marker-list-container
  .marker-filter-container
  .ui.fluid.dropdown
  > .dropdown.icon {
  float: right;
  color: white;
  font-size: 13px;
}
/*.credits-marker {border-left-color: #9ee965;}
.shot-marker    {border-left-color: #29acff;}
.beat-marker    {border-left-color: #ffea00;}
.scene-marker   {border-left-color: #f94a6f;}*/
.credits-marker {
  background-color: #9ee965;
}
.shot-marker {
  background-color: #29acff;
}
.beat-marker {
  background-color: #ffea00;
}
.scene-marker {
  background-color: #f94a6f;
}

.marker-color-bar {
  width: 4px;
  border-radius: 5px;
  left: 4px;
  top: 4px;
  bottom: 4px;
  /*background-color: #9ee965;*/
}

.marker.shot {
  padding: 0;
  border: 0;
  /*overflow: hidden;*/
}

.ui.multiple.dropdown {
  background: #3e4452;
  width: 100%;
}

.app-marker-list-container .ui.multiple.dropdown > .label {
  padding: 10px 20px;
  border: none;
  box-shadow: none;
  position: relative;
  width: 100%;
  border-radius: 5px;
  /*border-left: 3px solid;*/
}
.app-marker-list-container .ui.label > .delete.icon {
  position: absolute;
  right: 15px;
}

.app-marker-list-container
  .marker-filter-container
  .ui.fluid.multiple.search.selection.dropdown {
  width: 100%;
  position: static;
  background: #45505f;
}
.app-marker-list-container .ui.multiple.dropdown > a:first-of-type {
  margin-top: 10px;
}
.app-marker-list-container i.options.icon {
  background: black;
  padding: 5px 10px;
  width: auto;
  height: auto;
  border-radius: 5px;
}

/*.app-video-container {
    position: absolute;
    top: 50px;
    bottom: 0;
    left: 30%;
    width: 70%;
}*/
.marker-headshot {
  position: relative;
  width: auto;
  border: none;
  color: #fff;
  margin-bottom: 2px;
}

.marker-headshot
  .mediate-collaborator-image-container
  > *
  .mediate-collaborator-image-title {
  font-size: 12px;
  font-weight: normal;
  color: #fff !important;
}

.marker-headshot
  .mediate-collaborator-image-container
  > *
  .mediate-collaborator-image-subtitle {
  font-size: 10px;
  color: #fff !important;
}

@media only screen and (max-width: 875px) {
  .marker-headshot
    .mediate-collaborator-image-container
    .mediate-collaborator-image-title-container {
    display: none;
  }
}

.marker-headshot .ui.headshot.avatar.image:hover {
  opacity: 0.8;
}

.app-marker-list-container .marker-timecode {
  position: relative;
  flex: 0.3;
  /*left: -70px;*/
  text-align: center;
  /*top: 0;*/
  bottom: 0;
  right: 0;
  padding: 5px;
  margin: 0px;
  background: rgba(0, 0, 0, 0.7);
  /*width: 70px;*/
  font-size: 14px;
  /*font-weight: bold;*/
  color: rgba(157, 165, 181, 1);
}

.app-marker-list-container .marker-timecode.repeated {
  position: relative;
  /*left: -70px;*/
  text-align: center;
  /*top: 0;*/
  bottom: 0;
  right: 0;
  padding: 5px;
  margin: 0px;
  background: rgba(0, 0, 0, 0.7);
  /*width: 70px;*/
  font-size: 14px;
  /*font-weight: bold;*/
  color: rgba(157, 165, 181, 1);
}

.app-marker-list-container .marker-timecode.group {
  display: block;
  position: absolute;
  bottom: 0;
  /*margin-left: 5px;*/
}
ul .group .marker-timecode {
  display: none;
}
.group {
  position: relative;
}
.app-marker-list-container .marker-type {
  position: relative;
  margin: 10px 0px 0px 10px;
  flex: 1;
  font-size: 15px;
  color: rgba(255, 255, 255, 1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.marker-note-icon {
  padding-left: 10px;
}

.app-marker-list-container .marker-timecode .marker-timecode-o {
  color: rgba(157, 165, 181, 0.5);
}

.app-marker-list-container .marker-timecode-bar {
  width: 5px;
  background: rgb(69, 80, 95);
  border-radius: 5px;
  height: 30px;
  position: absolute;
  z-index: 5;
  left: -13px;
  top: 27px;
  height: 80%;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
}

.app-marker-list-container .marker-shot-image {
  width: 100%;
  height: auto;
}

.marker-note {
  margin: 26px 19px 0 10px;
  color: #e8e1b8;
  padding: 5px 10px;
  margin-bottom: 30px;
  font-size: 24px;
  line-height: 1.2;
}

.shot-marker .marker-note {
  margin: -66px 19px 10px 0;
  color: #e8e1b8;
  padding: 5px 10px;
  font-size: 14px;
}
.marker-edit {
  position: absolute;
  right: 5px;
  top: 8px;
}
.marker-edit .ui.icon.top.right.pointing.dropdown.button {
  padding: 3px 1px;
  background: black;
  color: white;
  border: 2px solid rgba(255, 255, 255, 0.3);
}
.marker-edit .ui.icon.top.right.pointing.dropdown.button:hover {
  border: 2px solid rgba(255, 255, 255, 0.9);
}
/*.note-input {
  width: 50%;
  position: relative;
  margin-left: 5%;
}
.note-input > .ui-form .field .ui-input input[type="text"] {
  background-color: #000;
}*/
.no-markers {
  display: none;
  color: rgba(157, 165, 181, 1);
  font-size: 15px;
  padding: 30px 20px;
  text-align: center;
  width: 280px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.shortcut-keys {
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 700;
  background: #21252c;
  color: white;
  border-radius: 3px;
  padding: 2px 4px;
  margin: 0 0 0 5px;
}
.marker-filter-container .shortcut-keys {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  height: 18px;
  margin: auto;
  right: 8px;
  color: #6a717c;
}
.scrolling.menu .shortcut-keys {
  color: white;
  background: gray;
}
.marker-filter-container i.filter.icon {
  margin-left: -53px !important;
  color: #9da5b5;
}
/*.marker-filter-container .text {
    margin-left: -41px;
}*/
.app-marker-list-container > * li:hover {
  background: rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.app-marker-list-container .ui.dropdown .menu > .input {
  margin: 0;
}
.marker-filter-container .ui.input input {
  border: none;
}
.marker-filter-container {
  background: #171a1f;
  padding: 5px;
  z-index: 5000;
  /*width: 29%;
    top: 0;
    left: 0;
    border: 1px solid;
    height: 50px;
    position: fixed;*/
}
.marker-filter-container a.ui.label.transition.visible {
  background: black;
  color: white;
}
.app-marker-list-container .ui.floating.dropdown.labeled.icon.button {
  width: calc(100% - 80px);
  position: relative;
  right: 9px;
  margin: 0 0 0 74px;
}
.app-marker-list-container .ui.fluid.multiple.search.selection.dropdown {
  /*width: calc(100% - 80px);*/
  position: relative;
  left: 0;
  /*margin: 0 0 0 74px;*/
}
.marker-edit,
.marker-headshot {
  transition: all 0.2s ease;
  opacity: 0;
}
li:hover > .marker-edit,
li:hover > * .marker-headshot,
li:focus > .marker-edit,
li:focus > .marker-headshot {
  opacity: 1;
}

i.icon.placeholder-image {
  position: relative;
  margin: 0 auto;
  display: block;
  top: 50%;
  width: 10%;
  height: auto;
}
.marker-headshot .collaborator-icon {
  position: absolute;
  bottom: 0;
  left: 20px;
  margin: 10px;
}
/*.marker-timecode:hover + .marker.shot-marker,
.marker-timecode:hover + .marker.shot-marker {
    cursor: pointer;
    background-color: red!important;
    color: blue!important;
    border: 1px solid yellow;
}*/

/* Add Marker Button */
.add-marker-container {
  position: fixed;
  top: 5px;
  /*left: 30%;*/
  z-index: 5000;
  overflow-x: show;
  /*width: 60%;*/
  margin: 5px;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
}

/*
  .add-marker-container .ui.fluid.search.selection.dropdown {
    position: relative;
    top: 0px;
    left: 0px;
    background: #29acff;
    color: white;
    max-width: 300px;
  }
*/

.add-marker-container .add-marker-button {
  position: relative;
  display: inline-block;
  margin-left: 5px;
}

.add-marker-container .mediate-shared-link-url.show {
  opacity: 1;
  transition: opacity 0.5s ease;
}

.add-marker-container .mediate-shared-link-url.hide {
  opacity: 0;
  transition: opacity 0.5s ease;
}

.add-marker-container .mediate-shared-link-url {
  color: #fff;
}

/* VIDEO PLAYER  */
.video-js {
  font-size: 15px !important;
}

.video-js .vjs-control-bar {
  background-color: transparent !important;
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(43, 51, 63, 0.55) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(43, 51, 63, 0.55) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(43, 51, 63, 0.55) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#8c2b333f',GradientType=0 );
}

/*.vjs-remaining-time.vjs-time-control.vjs-control {
    position: absolute;
    bottom: -67px;
    left: 20px;
    right: 20px;
    background: black;
    border-radius: 5px;
    position: absolute;
    left: 0;
    top: 50px;
    height: auto;
    width: 100%;
    border-radius: 5px;
}*/
.vjs-remaining-time-display {
  font-family: lato;
  font-weight: 300;
  /*color: #999fa9;*/
}

.marker-status {
  position: absolute !important;
  width: 30% !important;
  background: rgba(15, 19, 24, 0.85) !important;
  text-align: center;
  padding: 5px;
  height: auto;
  margin: 10px !important;
  color: #fff !important;
  top: 120px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
}

/*.marker-status.pending {
  opacity: 0 !important;
}

.marker-status i.icon {
  font-size: 40px;
  position: relative;
  top: 10px;
  border: none;
}*/

/* Mouse Hover*/
.video-js .vjs-progress-control .vjs-mouse-display {
  font-family: lato;
  z-index: 500 !important;
  outline: 1px solid rgba(255, 255, 255, 0.5);
}

/** Captions Settings **/
.vjs-tracksettings select {
  color: #000;
}

.vjs-caption-settings {
  background-color: rgba(34, 38, 44, 0.75);
  border-radius: 5px;
  font-family: lato, sans-sans-serif;
}

/*** NEW SCHEMA MODAL ***/
.mediate-schema-sidebar-dimmer {
  display: none;
}

.mediate-schema-sidebar-dimmer.active {
  position: absolute;
  display: block;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  cursor: pointer;
}
.mediate-schema-sidebar.hide {
  height: 0;
}
.mediate-schema-sidebar.show {
  height: 70vh;
}
.mediate-schema-sidebar {
  background: #131820;
  position: absolute;
  z-index: 10;
  bottom: 0;
  width: 100%;
  transition: height 500ms ease-in-out;
  box-shadow: 0 0px 12px rgba(0, 0, 0, 0.36), 0 0px 12px rgba(0, 0, 0, 0.43) !important;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}

.mediate-schema-sidebar-active-markers-container {
  flex: 0.2;
  min-height: 65px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #2e3542;
}

.mediate-schema-sidebar-active-markers-list,
.mediate-schema-sidebar-active-markers-list > * .simplebar-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
}

.mediate-schema-sidebar-active-marker-count {
  color: #fff;
  font-size: 18px;
  text-align: center;
  margin: 10px;
}

.mediate-schema-active-marker {
  padding: 5px;
  border-radius: 5px;
  margin: 5px;
}

.mediate-marker-shortcut-remove {
  border: none !important;
  box-shadow: none !important;
}

.mediate-schema-sidebar .mediate-schema-container {
  flex: 1;
  min-height: 0;
}

.mediate-schema-sidebar-header {
  color: #fff !important;
  margin: 20px !important;
}

/*** Shortcuts ***/
.mediate-create-shortcut-container {
  width: 100%;
  background: #131820;
  color: #fff;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 2px;
}

.mediate-create-shortcut-input {
  background: #000;
  padding: 5px;
  margin: 5px 0px;
  border-radius: 2px;
}

.mediate-create-shortcut-input:focus {
  outline: 1px solid teal;
}

.mediate-create-shortcut-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.mediate-create-shortcut-action {
  margin-right: 10px !important;
  cursor: pointer;
  font-size: 16px !important;
  opacity: 1;
}

.mediate-create-shortcut-action:hover {
  opacity: 0.6;
}

.mediate-marker-shortcut-label {
  background: #000;
  margin: 5px;
  padding: 5px;
}

.mediate-marker-shortcut-labels-container {
  margin: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  flex-wrap: wrap;
}

.mediate-marker-shortcut-label-detailed {
  font-size: 12px;
  padding: 0px 0px 0px 5px;
  margin: 5px;
  position: relative;
  display: flex;
  align-items: baseline;
  border-radius: 3px;
}

.mediate-marker-shortcut-label-shortcut {
  background: #2e3542;
  margin-left: 5px;
  height: 100%;
  padding: 3px 5px 3px 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.annotate-in-mediate-button {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 2;
}
/* MEDIA QUERIES */
/*@media only screen and (min-width: 800px) {
    .video-player-container{
        position: absolute;
        left: 0;
        right: 0;
        margin: auto !important;
        width: 800px;
        margin: 5%;
    }

}*/
