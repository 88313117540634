.user-account-container {
    width: 100%;
    height: 100%;
    background: #fff;
}

.mediate-account-section-profile-container {
    display: flex !important;
    justify-content: center;
    align-items: center;
}

/*** Cards ***/

.user-account-profile-card > .mediate-form-file-field {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.change-profile-image-button {
    cursor: pointer;
}

.change-profile-image-button:hover {
    opacity: 0.8;
}

.mediate-user-card-modal-content {
    display: flex !important;
    justify-content: center;
    align-items: center;
    background-color: #fff !important;
}

.mediate-user-card-modal-trigger-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    overflow: hidden;
    flex-wrap: wrap;
}

.mediate-collaborator-display-container > * .mediate-user-card-modal-trigger-container {
    border-bottom: 1px solid #ccc;
}

.mediate-user-card-modal-trigger {
    cursor: pointer;
    opacity: 1.0;
    transition: opacity 250ms ease-in !important;
}

.mediate-user-card-modal-trigger:hover {
    opacity: 0.7;
}

.mediate-user-card-modal-trigger-actions {
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: baseline;
}

.mediate-user-card-modal-trigger-actions > .toggle-icon-label {
    margin-right: 10px !important;
}

.mediate-collaborator-options-button {
    cursor: pointer;
}
/*** Stats ***/

.mediate-stats-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.mediate-stats-group {
    justify-content: center;
    align-items: center !important;
}

.mediate-stats-options {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 20px 10px 0px 10px;
}

.mediate-stat {
    display: flex !important;
    justify-content: center;
    flex-wrap: wrap;
}

/*** Technical Settings ***/
.mediate-tech-settings-label {
    width: 100%;
    text-align: justify;
    border-bottom: 1px solid #ccc;
    padding-bottom: 5px;
}

